import * as React from 'react';
import { ItemsPickerSimple } from './itemspicker.simple';
import { ItemsPickerAdvanced } from './itemspicker.advanced';
import type { IItemsPickerProps } from './itemspicker.props';

import './itemspicker.less';

export function ItemsPicker(props: IItemsPickerProps) {
    let content = null;
    if (props.pickerType === 'simple') {
        content = <ItemsPickerSimple {...props} />;
    }
    
    if (props.pickerType === 'advanced') {
        content = <ItemsPickerAdvanced {...props} />;
    }

    return <div className={"itemspicker " + (props.pickername ? "itemspicker-" + props.pickername : "")}>
        {content}
    </div>;
}

export function getExpectedPickerTypeFromItemsCount(itemsCount: number) {
    if (itemsCount < 9)
        return "simple";

    return "advanced";
}
