import { States } from '../services';

export const metadataMenuActions = {
    loadMenu() {
        return (dispatch, getState) => {
            const state: States.IAppState = getState();
            if (!state.appMetaData.loadMenu) {
                dispatch({ type: "METADATA_MENULOAD" });
            }
        };
    },

    showMenu(show: boolean) {
        return (dispatch) => {
            dispatch({ type: "METADATA_MENU", payload: show });
        };
    },

    showAssistant(show: boolean, question?: string) {
        return (dispatch) => {
            dispatch({ type: "METADATA_ASSISTANT", payload: { show, question } });
        };
    },

    toggleMenu() {
        return (dispatch, getState) => {
            const state = getState();
            dispatch({ type: "METADATA_MENU", payload: !state.appMetaData.showMenu });
        };
    }
};
