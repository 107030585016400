import { logging } from '@inwink/logging';
import { mediaquery } from '../components/appvisualstate';
import type { States } from './services';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { hasValidMembershipLevel } from '@@modules/community/data';
import { getPredicate } from '@inwink/expressions';

export const visualStates = ["XXXXS", "XXXS", "XXS", "XS", "S", "M", "L", "XL"];

const uilogger = logging.getLogger("UI");

export function getBlocTemplateFor(data: States.IDataStoreWithTemplates, itemtemplate: string | VisualTheme.IItemTemplate) {
    if (typeof itemtemplate === "string") {
        return getTemplateByName(data, itemtemplate);
    }

    return itemtemplate;
}

export function getBlocTemplate(data: States.IDataStoreWithTemplates, bloc: VisualTheme.IBlocContentTemplateBase) {
    const itemtemplate = bloc && bloc.properties && bloc.properties.template;

    return getBlocTemplateFor(data, itemtemplate);
}

export function getTemplateByName(data: States.IDataStoreWithTemplates, templatename: string) {
    if (!templatename || !data) {
        return undefined;
    }

    const tmp = data.templates.data.find((t) => t.tinyUrl === templatename && t.contentType === 'template');
    if (tmp) {
        return tmp.config;
    }
    if (!__SERVERSIDE__) {
        uilogger.warn("template not found for " + templatename);
    }
    return undefined;
}

export interface IItemsProperties {
    itemsAlign: any;
    itemsAlignCSS: string;
    itemsLayout: any;
    itemtemplate: any;
    itemsLayoutCSS: string;
    itemsLayoutClassName: string;
}

export function getItemsProperties(blocid: string, visualstate: string[],
    blocproperties, template, defaultAlign, defaultLayout): IItemsProperties {
    const tmpvisualstate = visualstate ? [...visualstate] : [];

    if (!(tmpvisualstate.indexOf("XXS") >= 0)) {
        tmpvisualstate.push("XXS");
    }
    const alignCSS = (rootclass, align, mediaSize) => {
        let content;
        if (align === "left") {
            content = "-webkit-box-pack: start; justify-content: flex-start;";
        } else if (align === "right") {
            content = "-webkit-box-pack: end; justify-content: flex-end;";
        } else if (align === "center") {
            content = "-webkit-box-pack: center; justify-content: center;";
        }

        if (content) {
            if (mediaSize && (mediaSize.minWidth !== undefined)) {
                return "@media screen and (min-width: "
                    + mediaSize.minWidth + "px){.approot #ct-" + blocid + " .inwink-items {"
                    + content
                    + "}}";
            }
            return ".approot" + rootclass + " #ct-" + blocid + " .inwink-items {"
                + content
                + "}";
        }
    };

    const layoutCSS = (rootclass, layout, mediaSize) => {
        let content;
        if (layout === "col1") {
            content = "width: calc(100% - 8px);";
        } else if (layout === "col2") {
            content = "width: calc(50% - 8px);";
        } else if (layout === "col3") {
            content = "width: calc(33.3333% - 8px);";
        } else if (layout === "col4") {
            content = "width: calc(25% - 8px);";
        }

        if (content) {
            if (mediaSize && (mediaSize.minWidth !== undefined)) {
                return "@media screen and (min-width: " + mediaSize.minWidth + "px){"
                    + ".approot #ct-" + blocid + " .inwink-items .inwink-item {" + content + "}}";
            }

            return ".approot" + rootclass + " #ct-" + blocid + " .inwink-items .inwink-item {"
                + content
                + "}";
        }
    };

    let itemsAlign = defaultAlign || "ukn";
    const itemsAlignCSS = [alignCSS("", defaultAlign || "left", null)];
    let itemsLayout = defaultLayout || "ukn";
    const itemsLayoutClassName = [];
    const itemsLayoutCSS = [layoutCSS("", (defaultLayout && defaultLayout.default) || defaultLayout || "col1", null)];
    if (blocproperties && blocproperties.itemsAlign) {
        itemsAlign = blocproperties.itemsAlign;
    } else if (template && template.itemsAlign) {
        itemsAlign = template.itemsAlign;
    }

    if (blocproperties && blocproperties.itemsLayout) {
        itemsLayout = blocproperties.itemsLayout;
    } else if (template && template.itemsLayout) {
        itemsLayout = template.itemsLayout;
    }

    if (itemsAlign && typeof itemsAlign !== "string") {
        let tmp = itemsAlign.default || "ukn";
        visualStates.forEach((s) => {
            if (itemsAlign[s]) {
                if (tmpvisualstate && tmpvisualstate.indexOf(s) >= 0) {
                    tmp = itemsAlign[s];
                }

                const content = alignCSS(".root-vstate-" + s, itemsAlign[s], mediaquery[s]);
                if (content) {
                    itemsAlignCSS.push(content);
                }
            }
        });

        if (tmp) {
            itemsAlign = tmp;
        }
    }

    if (itemsLayout && typeof itemsLayout !== "string") {
        let tmp = itemsLayout.default || "ukn";
        visualStates.forEach((s) => {
            if (itemsLayout[s]) {
                if (tmpvisualstate && tmpvisualstate.indexOf(s) >= 0) {
                    tmp = itemsLayout[s];
                }

                const content = layoutCSS(".root-vstate-" + s, itemsLayout[s], mediaquery[s]);
                if (content) {
                    itemsLayoutCSS.push(content);
                }
            }
        });

        const defaultL = itemsLayout.default || (defaultLayout && defaultLayout.default);
        if (defaultL) {
            itemsLayoutClassName.push("layout-default-" + defaultL);
        }
        for (const layout in mediaquery) {
            const currentLayout = itemsLayout[layout] || defaultL;
            if (currentLayout) {
                itemsLayoutClassName.push("layout-" + layout + "-" + currentLayout);
            }
        }

        if (tmp) {
            itemsLayout = tmp;
        }
    }

    return {
        itemsAlign: itemsAlign,
        itemsAlignCSS: itemsAlignCSS.join("\r\n"),
        itemsLayout: itemsLayout,
        itemtemplate: template,
        itemsLayoutCSS: itemsLayoutCSS.join("\r\n"),
        itemsLayoutClassName: itemsLayoutClassName.join(" ")
    };
}


export interface IDisplayContentParams {
    event: States.IEventState;
    community: States.ICommunityState;
    requireConnexion?: boolean;
    requireRegistration?: boolean;
    requireMembership?: boolean;
    membershipActive?: boolean;
    membershipLevelIds?: string[];
}

export function displayContent(user: States.IAppUserState, params: IDisplayContentParams): boolean {
    if (params.requireConnexion === true && !user.currentUser) {
        return false;
    }

    if (params.requireConnexion === false && user.currentUser) {
        return false;
    }

    if (params.event?.eventid) {
        if (params.requireRegistration === true && (!user.currentUser || !user.currentUser.isRegistered)) {
            return false;
        }

        if (params.requireRegistration === false) {
            if (user.currentUser?.isRegistered === true) {
                return false;
            }
        }
    } else if (params.community?.communityid) {
        const { hasMembership, membershipIsValid } = hasValidMembershipLevel(user.currentUser?.member);
        if (params.requireMembership === true) {
            const membershipLevelIds = params.membershipLevelIds;
            if (membershipLevelIds?.length && hasMembership
                && membershipLevelIds.indexOf(user.currentUser?.member?.membershipLevel?.id) === -1) {
                return false;
            }
            if (params.membershipActive) {
                if (!hasMembership || !membershipIsValid) {
                    return false;
                }
            } else {
                if (hasMembership && !membershipIsValid) {
                    return true;
                }
                return false;
            }
        }

        if (params.requireMembership === false) {
            if (hasMembership) {
                return false;
            }
        }
    }

    return true;
}

export function canShowCopilot(
    copilotConf: States.ICopilotConfiguration,
    props: {
        event?: States.IEventState, community?: States.ICommunityState,
        user?: States.IAppUserState, i18n?: States.i18nState
    }
) {
    if (copilotConf?.display?.condition) {
        const condition = copilotConf.display.condition;
        if (!displayContent(props.user,
            { ...condition, event: props.event, community: null })
        ) {
            return false;
        }

        if (condition.showIf && !condition.showIfPredicate) {
            condition.showIfPredicate = getPredicate(condition.showIf);
        }
        if (condition.showIfPredicate) {
            const res = condition.showIfPredicate({
                i18nstate: props.i18n,
                eventstate: props.event,
                userstate: props.user,
                event: props.event.detail,
                user: props.user?.currentUser?.detail
            });
            if (!res) {
                return false;
            }
        }
    }

    return true;
}    