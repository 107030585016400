import * as React from 'react';
import * as isEmpty from 'lodash/isEmpty';
import { templatedString } from '@inwink/utils/methods/templatedString';
import { getBlocTemplate } from '@@services/itemshelpers';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { States } from '@@services/services';
import { MemberInfoContextSource } from '@@community/components/memberinfocontextsource';
import type { IDynamicBlocProps, IDynamicBlocWithProps } from '@@pages/common';
import { TeaserItems } from '@@pages/teaseritems';
import { contentQuery, IContentQueryOptions } from '@@community/api/contents';
import type { ITeaserItemsBlocCommonProperties } from '@@pages/teaseritems.props';
import type { ICommunityContent, ICommunityContentType } from '@@community/data';
import { withI18nHelper } from '@inwink/i18n';
import type { Entities } from '@inwink/entities/entities';
import { getUrlSlug } from 'helpers';
import { getEmptyMessage } from '@@modules/community/features/bloc.teaser.helper';
import moment from 'moment';
import * as isEqual from 'lodash/isEqual';

export interface IBlocCommunityTeaserContentProps
    extends IDynamicBlocWithProps<IBlocCommunityTeaserContentBlocState, IBlocCommunityTeaserContentBlocProperties> {
    contentType: ICommunityContentType;
    detailpage: string;
    i18nHelper?: Entities.i18nHelper;
}

export interface IBlocCommunityTeaserContentBlocState {
    contents: ICommunityContent[];
    itemtemplate: VisualTheme.IItemTemplate;
    loading?: boolean;
    fromServer?: boolean;
}

export interface IBlocCommunityTeaserContentBlocProperties extends ITeaserItemsBlocCommonProperties {
    targetPage: any;
    recommandation?: boolean;
    bookmarked?: boolean;
    spotlights?: boolean;
    showAllEvents?: boolean;
    showEvents?: string
}

export function blocTeaserContentData(contentType: ICommunityContentType) {
    return (
        page: States.ICurrentPageState,
        statedata: IBlocCommunityTeaserContentBlocState,
        blc: VisualTheme.IBlocContentTemplate<IBlocCommunityTeaserContentBlocProperties>,
        state: States.IAppState,
        dispatch: any,
        isRefresh: boolean
    ) => {
        const blocdata = statedata;
        const bloc = blc;
        const itemtemplate = getBlocTemplate(state.community.data, bloc);
        blocdata.itemtemplate = itemtemplate;
        if (!blocdata.fromServer && !isRefresh) {
            blocdata.loading = true;
            blocdata.contents = null;
        }
        if (__SERVERSIDE__) {
            blocdata.fromServer = true;
            return loadContentData(
                state.community,
                contentType,
                blocdata,
                blc,
                page.context.entityKind,
                page.context.entityid,
                page.customcontext?.group?.id
            ).then((newblocdata) => {
                Object.assign(blocdata, newblocdata);
                return blocdata;
            });
        }
    };
}

function loadContentData(
    community: States.ICommunityState,
    contentType: ICommunityContentType,
    blocstate: IBlocCommunityTeaserContentBlocState,
    template: VisualTheme.IBlocContentTemplate<IBlocCommunityTeaserContentBlocProperties>,
    pageEntityKind: string,
    pageEntityId: string,
    groupId?: string
) {
    const filters: any = {};
    if (contentType === "IWEvent") {
        const now = moment().toISOString();
        
        const properties = template?.properties;
        if (properties?.showEvents) {
            switch (properties.showEvents) {
                case "coming":
                    filters.startDate = { $gt: now };
                    break;
                case "past":
                    filters.endDate = { $lt: now };
                    break;
                case "current":
                    filters.startDate = { $lt: now };
                    filters.endDate = { $gt: now };
                    break;
            }
        // Block à conserver pour rétrocompatibilité (#55441)
        } else if (properties?.showAllEvents === false) {
            filters.endDate = { $gt: now };
        }
    }

    if (pageEntityKind === "Topic" && pageEntityId) {
        filters.topics = {
            $any: {
                id: pageEntityId
            }
        };
    }

    if (pageEntityKind === "Company" && pageEntityId) {
        filters.companyId = {
            $eq: pageEntityId
        };
    }

    if (template.properties?.spotlights) {
        filters.spotlight = true;
    }



    if (pageEntityId) {
        filters.id = {
            $neq: pageEntityId
        };
    }

    const nbItems = template.properties.maxItems || 6;
    const query: IContentQueryOptions = {
        selects: {
            $all: true,
            info: {
                likes: true,
                comments: true
            },
            topics: {
                id: true,
                title: true
            },
            company: {
                id: true,
                name: true,
                logo: {
                    $all: true
                }
            }
        },
        page: {
            size: nbItems,
            index: 0
        },
        order: [
            {
                by: (template?.properties?.sort as any)?.propName || "publishedAt",
                desc: (template?.properties?.sort as any)?.order === "desc"
            }
        ],
        filters: filters,
        recommanded: template?.properties?.recommandation ? true : false,
        expression: template?.properties?.filterexpr,
        bookmarked: template?.properties?.bookmarked ? true : false,
        groupId: groupId
    };

    return contentQuery(community.requestManagers.apiFront, contentType, query).then((res: any) => {
        const newBlocState = {...blocstate};
        newBlocState.contents = res.data;
        newBlocState.loading = false;
        return newBlocState;
    }).then(null, (err) => {
        console.error("error getting contents", err);
        const newBlocState = {...blocstate};
        newBlocState.contents = null;
        newBlocState.loading = false;
        return newBlocState;
    });
}
@withI18nHelper()
export class BlocCommunityTeaserContent extends React.PureComponent<IBlocCommunityTeaserContentProps, any> {
    componentDidMount() {
        const blocState = {} as IBlocCommunityTeaserContentBlocState;
        if (!this.props.blocState?.fromServer || this.props.user.currentUser?.member) {
            blocState.loading = true;
            loadContentData(
                this.props.community,
                this.props.contentType,
                this.props.blocState,
                this.props.template,
                this.props.page.context.entityKind,
                this.props.page.context.entityid,
                this.props.customcontext?.group?.id
            ).then((newBlocState) => {
                this.props.update(newBlocState);
            });
        }

        if (this.props.blocState?.fromServer) {
            blocState.fromServer = false;
        }

        if (!this.props.blocState?.itemtemplate) {
            blocState.itemtemplate = getBlocTemplate(this.props.community.data, this.props.template);
        }

        if (!isEmpty(blocState)) {
            this.props.update(blocState);
        }
    }

    componentDidUpdate(prevProps: IDynamicBlocProps<IBlocCommunityTeaserContentBlocState>) {
        if (this.props.user?.currentUser && this.props.user?.currentUser != prevProps.user?.currentUser
            || !isEqual(this.props.bloctemplate.content, prevProps.bloctemplate.content)
        ) {
            const blocState = this.props.blocState;
            blocState.loading = true;
            loadContentData(
                this.props.community,
                this.props.contentType,
                this.props.blocState,
                this.props.template,
                this.props.page.context.entityKind,
                this.props.page.context.entityid,
                this.props.customcontext?.group?.id
            ).then((newBlocState) => {
                this.props.update(newBlocState);
            });
        }
    }

    onDataUpdate = (updatedContent) => {
        const updatedContents = this.props.blocState?.contents?.map((content) =>
            content?.id === updatedContent?.id ? updatedContent : content
        );
    
        this.props.update({
            ...this.props.blocState,
            contents: updatedContents
        });
    };

    getContentUrl = (content) => {
        if (this.props.template.properties && this.props.template.properties.targetPage) {
            return this.props.urlservice.pageUrl(templatedString(this.props.template.properties.targetPage,
                { ...this.props.datacontext, entity: content }));
        }
        const contentName = this.props.i18nHelper?.translateBag(content.title);
        return this.props.urlservice.pageUrl(`${this.props.detailpage}/${content.id}/${getUrlSlug(contentName)}`);
    };

    render() {
        if (!this.props.blocState) {
            return null;
        }

        const { blocState, i18n, contentType } = this.props;
        const { properties } = this.props.template;

        return <MemberInfoContextSource customcontext={this.props.customcontext}>
            <TeaserItems
                {...this.props}
                className="teaser-contents"
                dataKind={contentType}
                loading={blocState.loading}
                animateItems={true}
                defaultItemsLayout="col3"
                items={blocState.contents}
                linkto={!properties?.disableNavigation ? this.getContentUrl : null}
                allowEmpty={properties?.allowEmpty}
                emptyMessage={getEmptyMessage(i18n, properties)}
                itemtemplate={blocState.itemtemplate}
                onDataUpdate={this.onDataUpdate}
            />
        </MemberInfoContextSource>;
    }
}
