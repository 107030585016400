import * as React from 'react';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { States } from '@@services/services';
import {
    BlocTeaserMembers, blocTeaserMembersData,
    IBlocTeaserMembersBlocState, IBlocTeaserMembersProps
} from '@@modules/community/features/networking/blocs/teasermembers';
import { registeredMembersQuery } from '@@modules/community/api/events';
import { hasValidMembershipLevel, ICommunityIWEvent, IMember } from '@@modules/community/data';

export function blocTeaserEventRegisteredData(
    page: States.ICurrentPageState,
    statedata: IBlocTeaserMembersBlocState,
    blc: VisualTheme.IBlocContentTemplateBase,
    state: States.IAppState
) {
    blocTeaserMembersData(page, statedata, blc, state);
}

export class BlocTeaserEventRegistered extends React.PureComponent<IBlocTeaserMembersProps, any> {
    render() {
        const iwevent: ICommunityIWEvent = this.props.datacontext.entity;
        if (iwevent == null || (iwevent.eventType != "Webinar" && iwevent.eventType != "SmallEvent")) return null;
        
        const { hasMembership, membershipIsValid } = hasValidMembershipLevel(this.props.user?.currentUser?.member);
        if (!hasMembership || !membershipIsValid) return null;
        return <BlocTeaserMembers
            {...this.props}
            membersProvider={(requestManager, options) => {
                const newOptions = {...options};
                delete newOptions.contacts;
                delete newOptions.order;
                return registeredMembersQuery(requestManager, iwevent.id, options);
            }}
            showSeeMoreModal
            seeMoreSearchFilter={(member: IMember, search) => {
                return member.firstname?.toLowerCase()?.indexOf(search) > -1
                    || member.lastname?.toLowerCase()?.indexOf(search) > -1;
            }}
            seeMoreSearchPlaceholder='community.teasereventregistered.seemore.searchplaceholder'
            seeMoreItemsProvider={() => {
                return registeredMembersQuery(this.props.community.requestManagers.apiFront, iwevent.id, {
                    selects: {
                        $all: true
                    },
                    page: {
                        size: 200,
                        index: 0
                    },
                    // order: [
                    //     {
                    //         by: (this.props.template?.properties?.sort as any)?.propName || "createdAt",
                    //         desc: (this.props.template?.properties?.sort as any)?.order === "desc"
                    //     }
                    // ],
                    // contacts: true,
                    expression: this.props.template.properties.filterexpr,
                    allowSelf: true
                }).then((res) => res.data);
            }}
        />;
    }
}
