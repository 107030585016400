import * as React from 'react';
import { AppTextLabel } from '@inwink/i18n/apptextlabel';
import type { IItemTemplateProps } from '@@components/templates/itemtemplate';

interface IProductItemPrice extends IItemTemplateProps {
}

export class ProductItemPrice extends React.Component<IProductItemPrice, any> {

    // TODO Retirer la logique sur templateOptions après avoir retiré les options des gabarits
    shouldDisplayPriceHT = () => {
        const { customcontext, templateOptions } = this.props.datacontext;

        if (customcontext?.templateConfig?.showPriceHT === undefined
            && templateOptions?.showPriceHT === undefined
        ) {
            return false;
        }

        if (customcontext?.templateConfig?.showPriceHT !== undefined) {
            const showPriceHT = customcontext.templateConfig.showPriceHT;
            return showPriceHT;
        }

        return templateOptions?.showPriceHT;
    };

    // TODO Retirer la logique sur templateOptions après avoir retiré les options des gabarits
    shouldDisplayVat = () => {
        const { customcontext, templateOptions } = this.props.datacontext;

        if (customcontext?.templateConfig?.showPriceVAT === undefined
            && templateOptions?.showPriceVAT === undefined
        ) {
            return true;
        }

        if (customcontext?.templateConfig?.showPriceVAT !== undefined) {
            return customcontext?.templateConfig?.showPriceVAT;
        }

        return templateOptions?.showPriceVAT;
    };

    render() {
        const { customcontext } = this.props.datacontext;
        const { data } = this.props;

        return (
            <div className="price">
                {this.shouldDisplayPriceHT()
                    ? <h4 className='priceht'>
                        {customcontext.orderManager.formatPrice(data?.basePriceHt)}
                        <AppTextLabel
                            className='price-detail'
                            component="span"
                            i18n="orderregistration.basket.price.ht"
                        />
                    </h4>
                    : <h4 className='pricettc'>
                        {customcontext.orderManager.formatPrice(data?.basePriceTtc)}
                        <AppTextLabel
                            className='price-detail'
                            component="span"
                            i18n="orderregistration.basket.price.ttc"
                        />
                    </h4>
                }
                {this.shouldDisplayVat() &&
                    <AppTextLabel
                        component="div"
                        className="vat bloc-lighttext remark"
                        i18n={`orderregistration.basket.${this.shouldDisplayPriceHT()
                            ? "pricehtvat" : "pricevat"}`}
                        inject={
                            {
                                vat: customcontext.orderManager.formatPrice(data?.vatAmount)
                            }
                        }
                    />
                }
            </div>
        );
    }
}
