import * as React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { AnimatedSwitch } from '@inwink/animatedswitch';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { getPageClass, AsyncPage, IGetPageClassOptions } from "@@components/page";
import { AppLabel, IBestLanguage } from '@@services/i18nservice';
import type { States } from '@@services/services';
import type { IEntityProviderCallback } from '@@services/pageservice';
import { PageTransition } from "@@components/transitions";
import { contentDetail, contentInfo } from "@@community/api/contents";
import { membersQuery } from "@@community/api/members";
import { companiesQuery } from "@@community/api/companies";
import { topicDetail } from "@@community/api/topic";
import type { ICommunityContent, ICommunityContentType, ICommunityIWEvent } from '@@community/data';
import { getBaseFilters } from '@@community/data/baseFilters';
import type { ICommunityShellProps } from './communityshell';
import { forumDetail, forumsQuery } from '../api/forums';
import { forumSubjectsDetail, getFollowForumSubject, getLikeForumSubject } from '../api/forumsubjects';
import type { IGroupContext } from './group/communitygroupshell';
import { IDynamicPageProps } from '@@components/dynamicpage/dynamicpage.props';
import { companyofferingQuery } from '../api/companyoffering';
import { productsQuery } from '../api/products';
import type { ICommunityRequestManager } from '@@services/apiaccessprovider.definition';
import { isFromServer } from '@@components/dynamicpage/helpers';
import { modulesActions } from '@@routes/appmodules';
import { actions as i18nActions } from '@@services/i18nservice';
import { AppShellCommunityCopilot, AppShellCommunityCopilotTrigger } from './communityshell.copilot';
import moment from 'moment';
import { isRegisteredToEvent } from '../api/events';

export interface ICommunityRoutesProps extends ICommunityShellProps {
    visualConfiguration: Entities.IVisualConfigurationTemplate;
    webmasterConfiguration: VisualTheme.IWebmasterConfig;
    copilotConfiguration: States.ICopilotConfiguration;
    pagefooter: Entities.IContentTemplate;
    modulesActions: typeof modulesActions;
    communityGroupContext?: IGroupContext;
}

export interface ICommunityRoutesState {
    routes: any[];
}

class CommunityRoutesComponent extends React.Component<ICommunityRoutesProps, ICommunityRoutesState> {
    currentMatch: string;

    constructor(props: ICommunityRoutesProps) {
        super(props);

        this.state = {
            routes: this.computeRoutes(props)
        };
    }

    componentDidUpdate(prevProps: ICommunityRoutesProps) {
        if (this.props.community && ((this.props.community && this.props.community.communityid)
            !== (prevProps.community && prevProps.community.communityid))) {
            this.setState({
                routes: this.computeRoutes(this.props)
            });
        }
    }

    getContent(contentid: string, contentType: ICommunityContentType) {
        return communityContentProvider.getCommunityContent(
            this.props.page,
            this.props.user, this.props.community.requestManagers.apiFront,
            this.props.community.communityid, contentType, contentid
        );
    }

    getMember(memberId) {
        return membersQuery(this.props.community.requestManagers.apiFront, {
            filters: {
                id: memberId
            },
            allowSelf: true
        }).then((res) => {
            return res.data[0];
        });
    }

    getCompany(companyId) {
        return companiesQuery(this.props.community.requestManagers.apiFront, {
            selects: {
                $all: true,
                topics: {
                    id: true,
                    title: true
                }
            },
            filters: {
                id: companyId
            }
        }).then((res) => {
            return res.data[0];
        });
    }

    getCompanyOffering(companyofferingId) {
        return companyofferingQuery(this.props.community.requestManagers.apiFront, {
            filters: {
                id: companyofferingId
            },
            selects: {
                $all: true,
                company: {
                    $all: true
                },
                topics: {
                    id: true,
                    title: true
                }
            }
        }).then((res) => {
            return res.data[0];
        });
    }

    getProduct(productId) {
        return productsQuery(this.props.community.requestManagers.apiFront, {
            selects: {
                $all: true
            },
            filters: {
                id: productId
            }
        }).then((res) => {
            return res.data[0];
        });
    }

    getForum(forumId) {
        return forumDetail(this.props.community.requestManagers.apiFront, forumId).then((res: any) => {
            return res?.data[0];
        });
    }

    getGroupForum(forumId, groupId) {
        return forumsQuery(this.props.community.requestManagers.apiFront, {
            filters: {
                id: forumId,
            },
            groupId: groupId,
            selects: {
                $all: true,
                topics: {
                    id: true,
                    title: true
                }
            }
        }).then((res) => {
            return res.data[0];
        });
    }

    getForumSubject(forumId, subjectId) {
        const apiFront = this.props.community.requestManagers.apiFront;
        return forumSubjectsDetail(apiFront, forumId, subjectId).then((res) => {
            const forumSubject = res.data[0];

            if (this.props.user.currentUser?.member?.id) {
                return Promise.all([
                    getFollowForumSubject(apiFront, forumId, subjectId),
                    getLikeForumSubject(apiFront, forumId, subjectId)
                ]).then(([resFollow, resLike]) => {
                    forumSubject._isFollowed = resFollow.followed;
                    forumSubject._isLiked = resLike.liked;
                    return forumSubject;
                }, () => forumSubject);
            }

            return Promise.resolve(forumSubject);
        }, (err) => {
            return this.manageEntityError(err);
        });
    }

    getEntity = (entityKind: string, entityId: string, parentEntityId?: string) => {
        if (entityKind === "Member") {
            return this.getMember(entityId);
        }
        if (entityKind === "Company") {
            return this.getCompany(entityId);
        }
        if (entityKind === "companyoffering") {
            return this.getCompanyOffering(entityId);
        }
        if (entityKind === "Topic") {
            return topicDetail(this.props.community.requestManagers.apiFront, entityId);
        }
        if (entityKind === "Forum") {
            const ids = entityId?.split('#');
            if (ids && ids.length === 2) {
                const forumId = ids[0];
                const groupId = ids[1];
                return this.getGroupForum(forumId, groupId);
            } else {
                return this.getForum(ids[0]);
            }
        }
        if (entityKind === "ForumSubject") {
            const forumId = parentEntityId;
            return this.getForumSubject(forumId, entityId);
        }
        if (entityKind === "Group") {
            if (this.props.communityGroupContext?.group) {
                return Promise.resolve(this.props.communityGroupContext.group);
            }
            return Promise.resolve(null);
        }
        if (entityKind === "ProductCommunity") {
            return this.getProduct(entityId);
        }
        return this.getContent(entityId, entityKind as any);
    };

    manageEntityError = (err) => {
        if (err.status === 403 && !this.props.user?.currentUser) {
            return Promise.reject({ entityStatus: 403 });
        }
    };

    computeRoutes(props: ICommunityRoutesProps) {
        const rootPath = props.match.path;
        return computeCommunityRoutes(rootPath, props.community?.detail, this.getEntity, this.props.communityGroupContext?.group);
    }

    renderSection = (props) => {
        const classes = ["app-pages"];

        if (this.props.visualConfiguration) {
            if (this.props.visualConfiguration.global && this.props.visualConfiguration.global.appTheme) {
                classes.push(this.props.visualConfiguration.global.appTheme);
            }
        }

        if (this.props.copilotConfiguration?.enabled === true) {
            classes.push("with-assistant");
            return <section id="main-content" className={classes.join(' ')}>
                <AppShellCommunityCopilot {...this.props} />
                <div className="appshell-pagewrapper">
                    {props.children}
                    <AppShellCommunityCopilotTrigger {...this.props} />
                </div>

            </section>;
        }

        return <section id="main-content" className={classes.join(' ')}>
            {props.children}
        </section>;
    };

    render() {
        // if (!__SERVERSIDE__ && !this.props.user.isSyncReady) {
        //     return null;
        // }

        const routes = this.state.routes.map((r) => <Route
            key={r.path || 'Une erreur est survenue'}
            exact={r.exact}
            path={r.path}
            render={(pp: any) => {
                const props = pp;
                this.currentMatch = props.match.url;
                if (this.currentMatch[this.currentMatch.length - 1] !== '/') {
                    this.currentMatch += '/';
                }

                const renderProps: Partial<IDynamicPageProps> = {
                    ...props,
                    event: this.props.community,
                    rootwebsite: this.props.rootwebsite,
                    user: this.props.user,
                    i18n: this.props.i18n,
                    modulesActions: this.props.modulesActions,
                    webmasterConfiguration: this.props.webmasterConfiguration,
                    visualConfiguration: this.props.visualConfiguration,
                    pagefooter: this.props.pagefooter,
                    visualstate: this.props.visualstate,
                    customcontext: this.props.communityGroupContext
                };

                if (r.routekey) {
                    (renderProps as any).key = r.routekey(renderProps);
                }

                if (r.lngcode) {
                    props.match.params.lngcode = r.lngcode;
                }

                const lngcode = props.match.params.lngcode;
                if (lngcode && this.props.location.pathname === props.location.pathname
                    && lngcode !== this.props.i18n.currentLanguageCode) {
                    const lng = ((this.props.i18nActions.determineBestLanguage(lngcode)) as any) as IBestLanguage;
                    this.props.i18nActions.loadLanguage(lng.language, lng.dateDisplayLanguage);
                }

                if (r.render) {
                    return r.render(renderProps);
                }

                return React.createElement(r.component, renderProps);
            }}
        />);

        const style: any = {};
        if (this.props.visualConfiguration && this.props.visualConfiguration.global) {
            style.backgroundColor = this.props.visualConfiguration.global.backgroundColor;
            if (this.props.visualConfiguration.global.backgroundImage) {
                style.backgroundImage = "url(\"" + this.props.visualConfiguration.global.backgroundImage + "\")";
            }
        }

        let currentLocationKey = this.props.location.key;
        if (!currentLocationKey) {
            currentLocationKey = this.props.location.pathname + this.props.match.params;
        }

        return <AnimatedSwitch
            location={this.props.location}
            match={this.props.match}
            transitionComponent={PageTransition}
            component={this.renderSection}
            style={style}
        >
            {routes}
        </AnimatedSwitch>;
    }
}

function mapDispatchToProps(dispatch) {
    return {
        modulesActions: bindActionCreators(modulesActions, dispatch),
        i18nActions: bindActionCreators(i18nActions, dispatch)
    };
}

export const CommunityRoutes: new (any) => React.Component<ICommunityRoutesProps, any> = connect(
    null,
    mapDispatchToProps)(withRouter(CommunityRoutesComponent as any) as any) as any;

export function addCommunityRoutes(
    routes: any[],
    _rootPath: string,
    communityDetail: States.ICommunityDetail,
    entityProvider: IEntityProviderCallback,
    customEntity?: any
) {
    let rootPath = _rootPath;
    let baseContext: IGetPageClassOptions = { application: "community" };
    if (rootPath === "/") rootPath = "";
    if (rootPath.includes("/g/:groupid/:slug?")) {
        baseContext = {
            application: "communitygroup",
            pageFilter: (template, location, match) => {
                return (template as any).groupId === match.params.groupid;
            },
            customEntity: customEntity
        };
        routes.push({
            path: rootPath + "/",
            exact: true,
            component: getPageClass("home", "homepage", {
                ...baseContext,
                entityid: (args) => (args.match?.params?.groupid?.toLowerCase() || ""),
                entitykind: "Group",
                //requireEntity: true,                
                entityProvider: entityProvider
            })
        });
    } else {
        routes.push({
            path: rootPath + "/",
            exact: true,
            component: getPageClass("home", "homepage", baseContext)
        });
    }

    routes.push({
        path: rootPath + "/",
        exact: true,
        component: getPageClass("home", "homepage", baseContext)
    });

    routes.push(
        {
            path: rootPath + "/feed",
            exact: true,
            component: getPageClass("feed", "feedpage", baseContext)
        },
        {
            path: rootPath + "/topics",
            exact: true,
            component: getPageClass("topics", "topicspage", { ...baseContext, entitykind: "Topic" })
        },
        {
            path: rootPath + "/topics/:topicid/:slug?",
            exact: true,
            component: getPageClass(
                "topicdetail",
                "topicdetailpage",
                {
                    ...baseContext,
                    entitykind: "Topic",
                    entityid: (args) => (args.match?.params?.topicid?.toLowerCase() || ""),
                    requireEntity: true,
                    refreshEntityWhenLogged: true,
                    entityProvider: entityProvider
                }
            )
        },
        {
            path: rootPath + "/documents",
            exact: true,
            component: getPageClass("documents", "documentspage", { ...baseContext, entitykind: "DocumentCommunity" })
        },
        {
            path: rootPath + "/documents/:contentid/:slug?",
            exact: true,
            component: getContentDetailPageComponent(
                "documentdetail", "documentdetailpage", "DocumentCommunity", baseContext, entityProvider),
        },
        {
            path: rootPath + "/vods",
            exact: true,
            component: getPageClass("vods", "vodspage", { ...baseContext, entitykind: "Vod" })
        },
        {
            path: rootPath + "/vods/:contentid/:slug?",
            exact: true,
            component: getContentDetailPageComponent(
                "voddetail",
                "voddetailpage", "Vod", baseContext, entityProvider),
        },
        {
            path: rootPath + "/podcasts",
            exact: true,
            component: getPageClass("podcasts", "podcastspage", { ...baseContext, entitykind: "Podcast" })
        },
        {
            path: rootPath + "/podcasts/:contentid/:slug?",
            exact: true,
            component: getContentDetailPageComponent(
                "podcastdetail",
                "podcastsdetailpage", "Podcast", baseContext, entityProvider),
        },
        {
            path: rootPath + '/webinarstudio/:contentid',
            exact: true,
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        import(
                            /* webpackChunkName: "mod-commu-webinarstudio" */ "../routes/webinar/webinarstudio"
                        ).then((mod) => {
                            complete(mod.WebinarStudioPage);
                        });
                    }}
                />;
            }
        },
        {
            path: rootPath + '/webinarplayer/:contentid',
            exact: true,
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        import(
                            /* webpackChunkName: "mod-commu-webinarplayer" */ "../routes/webinar/webinarplayer"
                        ).then((mod) => {
                            complete(mod.WebinarPlayerPage);
                        });
                    }}
                />;
            }
        },
        {
            path: rootPath + "/events",
            exact: true,
            component: getPageClass("iwevents", "iweventspage", { ...baseContext, entitykind: "IWEvent" })
        },
        {
            path: rootPath + "/events/:contentid/:slug?",
            exact: true,
            component: getContentDetailPageComponent(
                "iweventdetail",
                "iweventdetailpage", "IWEvent", baseContext, entityProvider),
        },
        {
            path: rootPath + "/articles",
            exact: true,
            component: getPageClass("articles", "articlespage", { ...baseContext, entitykind: "Article" })
        },
        {
            path: rootPath + "/articles/:contentid/:slug?",
            exact: true,
            component: getContentDetailPageComponent(
                "articledetail",
                "articledetailpage", "Article", baseContext, entityProvider),
        },
        {
            path: rootPath + "/members",
            exact: true,
            component: getPageClass("members", "memberspage", { ...baseContext, entitykind: "Member" })
        },
        {
            path: rootPath + "/members/:memberid",
            exact: true,
            component: getPageClass(
                "memberdetail",
                "memberdetailpage",
                {
                    ...baseContext,
                    entitykind: "Member",
                    entityid: (args) => (args.match?.params?.memberid?.toLowerCase() || ""),
                    requireEntity: true,
                    refreshEntityWhenLogged: true,
                    entityProvider: entityProvider
                }
            )
        },
        {
            path: rootPath + '/messages',
            exact: false,
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        import(/* webpackChunkName: "mod-commu-messages" */ "../routes/messages").then((mod) => {
                            complete(mod.MessagesPage);
                        });
                    }}
                />;
            }
        },
        {
            path: rootPath + '/content/:contentpagetemplate',
            exact: true,
            routekey: (props) => props.match.params.contentpagetemplate,
            component: getPageClass((args) => {
                return args.match && args.match.params.contentpagetemplate;
            }, "communitycontentpage", { ...baseContext, contenttype: "contentpage" })
        },
        {
            path: rootPath + "/companies",
            exact: true,
            component: getPageClass("companies", "companiespage", { ...baseContext, entitykind: "Company" })
        },
        {
            path: rootPath + "/companies/:companyid/:slug?",
            exact: true,
            component: getPageClass(
                "companydetail",
                "companydetailpage",
                {
                    ...baseContext,
                    entitykind: "Company",
                    entityid: (args) => (args.match?.params?.companyid?.toLowerCase() || ""),
                    requireEntity: true,
                    refreshEntityWhenLogged: true,
                    entityProvider: entityProvider
                }
            )
        },
        {
            path: rootPath + "/offerings",
            exact: true,
            component: getPageClass("offerings", "offeringspage", { ...baseContext, entitykind: "companyoffering" })
        },
        {
            path: rootPath + "/offerings/:companyofferingid/:slug?",
            exact: true,
            component: getPageClass(
                "companyofferingdetail",
                "companyofferingdetailpage",
                {
                    ...baseContext,
                    entitykind: "companyoffering",
                    entityid: (args) => (args.match?.params?.companyofferingid?.toLowerCase() || ""),
                    requireEntity: true,
                    refreshEntityWhenLogged: true,
                    entityProvider: entityProvider
                }
            )
        },
        {
            path: rootPath + "/products",
            exact: true,
            component: getPageClass("products", "productspage", { ...baseContext, entitykind: "ProductCommunity" })
        },
        {
            path: rootPath + "/forums",
            exact: true,
            component: getPageClass("forums", "forumspage", { ...baseContext, entitykind: "Forum" })
        },
        {
            path: rootPath + "/forums/:forumid/:slug?",
            exact: true,
            component: getPageClass(
                "forumdetail",
                "forumdetailpage",
                {
                    application: "community",
                    entitykind: "Forum",
                    entityid: (args) => {
                        const forumId = args.match?.params?.forumid?.toLowerCase();
                        const groupId = args.match?.params?.groupid?.toLowerCase();
                        return forumId + (groupId ? "#" + args.match?.params?.groupid?.toLowerCase() : "") || "";
                    },
                    requireEntity: true,
                    refreshEntityWhenLogged: true,
                    entityProvider: entityProvider
                }
            )
        },
        {
            path: rootPath + "/forums/:forumid/subjects/:subjectid/:slug?",
            exact: true,
            component: getPageClass(
                "forumsubjectdetail",
                "forumsubjectdetailpage",
                {
                    application: "community",
                    entitykind: "ForumSubject",
                    // entityid: (args) => {
                    //     const forumId = args.match?.params?.forumid?.toLowerCase();
                    //     const subjectId = args.match?.params?.subjectid?.toLowerCase();
                    //     return forumId && subjectId ? `${forumId}#${subjectId}` : "";
                    // },
                    parententityid: (args) => (args.match?.params?.forumid?.toLowerCase() || ""),
                    entityid: (args) => (args.match?.params?.subjectid?.toLowerCase() || ""),
                    requireEntity: true,
                    refreshEntityWhenLogged: true,
                    entityProvider: entityProvider
                }
            )
        },
        {
            path: rootPath + "/groups",
            exact: true,
            component: getPageClass("groups", "groupspage", { ...baseContext, entitykind: "Group" })
        },
        {
            path: rootPath + "/unsubscribe",
            exact: true,
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        import(
                            /* webpackChunkName: "mod-commu-unsubscribe" */ "../components/unsubscribe"
                        ).then((mod) => {
                            complete(mod.UnsubscribePage);
                        });
                    }}
                />;
            }
        },
        {
            path: rootPath + '/member-workspace',
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        const p = props.modulesActions.getModule("mod-commu-memberworkspace") as any;
                        p.then((memberworkspace) => {
                            complete(memberworkspace.indexRoute);
                        });
                    }}
                />;
            }
        },
        {
            path: rootPath + '/member-workspace/:target/:content?',
            // lngcode: lngcode,
            component: getPageClass("member-workspace", "memberworkspacepage", { entitykind: "Member" })
        },
        {
            path: rootPath + '/company-workspace',
            exact: true,
            component: getPageClass(
                "company-workspace",
                "companyworkspacepage",
                {
                    ...baseContext,
                    entitykind: "Company",
                }
            )
        },
        {
            path: rootPath + '/company-workspace/r/:target/:content?',
            exact: true,
            component: getPageClass(
                "company-workspace",
                "companyworkspacepage",
                {
                    ...baseContext,
                    entitykind: "Company",
                }
            )
        },
        {
            path: rootPath + '/company-workspace/:companyworkspaceid',
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        import(
                            /* webpackChunkName: "mod-commu-unsubscribe" */ "../features/companyworkspace/companyshell"
                        ).then((mod) => {
                            complete(mod.CompanyWorkspaceShell);
                        });
                    }}
                />;
            }
        },
        {
            path: rootPath + "/ticketing",
            exact: true,
            component: getPageClass("ticketing", "ticketingpage", baseContext)
        },
        {
            path: rootPath + '/orderredirect',
            exact: true,
            // eslint-disable-next-line react/display-name
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        import(
                            /* webpackChunkName: "mod-commu-orderredirect" */ "../routes/order/orderredirect"
                        ).then((page) => {
                            complete(page.OrderRedirectPage);
                        });
                    }}
                />;
            }
        },
    );
}

export function computeCommunityRoutes(
    _rootPath: string,
    communityDetail: States.ICommunityDetail,
    entityProvider: IEntityProviderCallback,
    customEntity?: any
): any[] {
    const routes = [];

    let rootPath = _rootPath;
    if (rootPath === "/") rootPath = "";

    addCommunityRoutes(routes, rootPath, communityDetail, entityProvider, customEntity);

    // add group routes
    const groupPath = rootPath + "/g/:groupid/:slug?";
    // const groupRoutes = [];
    // addCommunityRoutes(groupRoutes, groupPath, communityDetail, entityProvider);

    routes.push(
        {
            path: groupPath,
            render: (props: any) => {
                return <AsyncPage
                    componentProps={props}
                    getComponent={(complete) => {
                        import(/* webpackChunkName: "mod-commu-groupshell" */ "./group/communitygroupshell").then((mod) => {
                            complete(mod.CommunityGroupShell);
                        });
                    }}
                />;
            }
        }
    );

    routes.push({
        // eslint-disable-next-line react/display-name
        component: () => <div className="app-page">
            <div className="dynamicpage pagenotfound">
                <div className="update-container">
                    <AppLabel component="div" className="message" i18n="page.notfound" />
                </div>
            </div>
        </div>
    });

    return routes;
}

function getContentDetailPageComponent(
    pageTemplate: string, pageClassName: string, entityName: string, baseContext: any, entityProvider: IEntityProviderCallback
) {
    return getPageClass(
        pageTemplate,
        pageClassName,
        {
            ...baseContext,
            entitykind: entityName,
            entityid: (args) => (args.match?.params?.contentid?.toLowerCase() || ""),
            requireEntity: true,
            refreshEntityWhenLogged: true,
            entityProvider: entityProvider
        }
    );
}

interface IContentInfoProviderData {
    entities: Record<string, IContentInfoEntityItem>;
}

interface IContentInfoEntityItem {
    items: Record<string, IContentInfoEntityData>;
}

interface IContentInfoEntityData {
    id?: string;
    userId?: string;
    partialContent?: boolean;
    entity?: Promise<any>;
    ts: Date;
}


class ContentInfoProvider {
    data: Record<string, IContentInfoProviderData> = {};

    getCommunityContent(
        page: States.ICurrentPageState,
        user: States.IAppUserState, requestMgr: ICommunityRequestManager,
        communityId: string, contentType: ICommunityContentType, contentid: string
    ) {
        const selects = getBaseFilters(user?.currentUser?.member, contentType);
        if (__SERVERSIDE__) {
            return contentInfo(requestMgr, contentType, contentid, {
                selects: selects
            }).then((res) => {
                const entity = res.data[0];
                return entity;
            });
        }

        const fromServer = isFromServer();

        let communityData = this.data[communityId];
        if (!communityData) {
            communityData = {
                entities: {}
            };
            this.data[communityId] = communityData;
        }

        let entityData = communityData.entities[contentType];
        if (!entityData) {
            entityData = {
                items: {}
            };
            communityData.entities = {};
            communityData.entities[contentType] = entityData;
        }

        const currentUserId = user.currentUser?.detail?.id;
        if (!currentUserId
            && fromServer
            && page
            && page.context.entityKind == contentType
            && page.context.entityid == contentid
            && page.context.entity) {

            entityData.items[contentid] = {
                id: contentid,
                ts: new Date(),
                userId: currentUserId,
                partialContent: page.context.entity._hasPartialContent,
                entity: Promise.resolve(page.context.entity)
            };
            return Promise.resolve(page.context.entity);
        }

        let entityItem = entityData.items[contentid];

        if (entityItem && entityItem.id === contentid) {
            const diff = new Date().getTime() - entityItem.ts.getTime();
            if (diff < 5000 && entityItem.userId === currentUserId) {
                return entityItem.entity;
            }
        }

        entityItem = {
            id: contentid,
            ts: new Date(),
            userId: currentUserId,
            partialContent: true,
            entity: contentDetail(requestMgr, contentType, contentid, {
                selects: selects
            }).then((res) => {
                const entity = res.data[0];
                entityItem.partialContent = (entity as any)._hasPartialContent;
                return _memberContentEnricher(requestMgr, user, contentType, contentid, entity);
            }, (err) => {
                console.error("error getting content " + contentType + " " + contentid, err);
                entityData.items[contentid] = null;
            })
        };
        entityData.items = {};
        entityData.items[contentid] = entityItem;

        return entityItem.entity;
    }
}

const communityContentProvider = new ContentInfoProvider();

function _memberContentEnricher(requestManager: ICommunityRequestManager,
    user: States.IAppUserState,
    contentType: ICommunityContentType,
    contentId: string,
    entity: ICommunityContent) {
    if (contentType == "IWEvent" && entity) {
        const iwevent = (entity as ICommunityIWEvent);
        if (iwevent.startDate && !iwevent.$startDate) {
            iwevent.$startDate = moment(iwevent.startDate);
        }
        if (iwevent.endDate && !iwevent.$endDate) {
            iwevent.$endDate = moment(iwevent.endDate);
        }
        if (user.currentUser?.member?.id) {
            if (iwevent.$endDate && iwevent.$endDate.isAfter(moment())) {
                const inwinkRegistration = iwevent.eventType == "InwinkEvent"
                    && (iwevent.inwinkEventRegistrationMode == "Direct"
                        || (iwevent.inwinkEventRegistrationMode == "EventWebsite" && iwevent.inwinkEventUrl));
                const registration = iwevent.eventType == "SmallEvent"
                    || iwevent.eventType == "Webinar";
                if (inwinkRegistration || registration) {
                    if (registration) {
                        return isRegisteredToEvent(requestManager, iwevent.id).then((res) => {
                            entity.isRegistered = res?.isRegistered ?? false;
                            return entity;
                        }, (err) => {
                            console.log(err);
                            return entity;
                        });
                    } else if (inwinkRegistration) {
                        const contactEvent = user.currentUser.member?.inwinkcustomercontact?.contactEvents
                            ?.filter((e) => e.eventId == iwevent.inwinkEventId);
                        if (contactEvent && contactEvent[0]?.isRegistered) {
                            entity.isRegistered = true;
                        } else {
                            entity.isRegistered = false;
                        }
                    }
                }
            }
        }
    }
    return Promise.resolve(entity);
}