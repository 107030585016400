/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/dot-notation */
import { registerAsyncItemTemplatePart } from '@inwink/itemtemplate/templatecatalog';
import type { States } from '@@services/services';
import { modules } from '@@routes/appmodules';
import { asyncBlocs } from '@@pages/blocscatalog';
import { registerAsyncEntityAction } from '@@pages/entitydetail/actionscatalog';
import { registerAsyncActionModule } from '@@pages/entitydetail/actions/actions.modules';
import { registerFilterComponent } from '@@components/entities/entityfilters';
import { TimeSlotDaysFilter } from '../components/entityfilters/entityfilters.timeslotdays';
import { TimeSlotHourFilter } from '../components/entityfilters/entityfilters.timeslothours';

export function offeringTeasersModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-offering-teasers" });
    return import(/* webpackChunkName: "mod-offering-teasers" */ './module.offering.teasers');
}
modules["mod-offering-teasers"] = offeringTeasersModule;

export function sessionTeasersModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-session-teasers" });
    return import(/* webpackChunkName: "mod-session-teasers" */ './module.session.teasers');
}
modules["mod-session-teasers"] = sessionTeasersModule;

export function eventCountdownModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-eventcountdown" });
    return import(/* webpackChunkName: "mod-eventcountdown" */ '../../../components/templates/eventcountdown');
}
modules["mod-eventcountdown"] = eventCountdownModule;

export function offeringsModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-offerings" });
    return import(/* webpackChunkName: "mod-offerings" */ './module.offerings');
}
modules["mod-offerings"] = offeringsModule;

export function controlPartnerWorkspaceTeaserModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-control-partnerworkspaceteaser" });
    return import(
        /* webpackChunkName: "mod-control-partnerworkspaceteaser" */
        '@@partnerworkspace/partnerworkspace.teaser'
    );
}
modules["mod-control-partnerworkspaceteaser"] = controlPartnerWorkspaceTeaserModule;

export function roomTeasersModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-room-teasers" });
    return import(/* webpackChunkName: "mod-room-teasers" */ './module.room.teasers');
}
modules["mod-room-teasers"] = roomTeasersModule;

export function exhibitorTeasersModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-exhibitor-teasers" });
    return import(/* webpackChunkName: "mod-exhibitor-teasers" */ './module.exhibitor.teasers');
}
modules["mod-exhibitor-teasers"] = exhibitorTeasersModule;

export function speakerTeasersModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-speaker-teasers" });
    return import(/* webpackChunkName: "mod-speaker-teasers" */ './module.speakers.teasers');
}
modules["mod-speaker-teasers"] = speakerTeasersModule;

export function exhibitorsMeetingRoomsTeasersModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-exhibitors-meeting-rooms-teasers" });
    return import(/* webpackChunkName: "mod-exhibitors-meeting-rooms-teasers" */ './module.exhibitors.meetingrooms.teasers');
}
modules["mod-exhibitors-meeting-rooms-teasers"] = exhibitorsMeetingRoomsTeasersModule;

export function exhibitorsTeamMembersTeasersModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-exhibitors-team-members-teasers" });
    return import(/* webpackChunkName: "mod-exhibitors-team-members-teasers" */ './module.exhibitors.teammembers.teasers');
}
modules["mod-exhibitors-team-members-teasers"] = exhibitorsTeamMembersTeasersModule;

export function thematicTrailsModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-thematictrails" });
    return import(/* webpackChunkName: "mod-thematictrails" */ './module.thematictrails');
}
modules["mod-thematictrails"] = thematicTrailsModule;

export function searchTeaserModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-searchteaser" });
    return import(/* webpackChunkName: "mod-searchteaser" */ './module.searchteaser');
}
modules["mod-searchteaser"] = searchTeaserModule;

export function roundTablesListModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-roundtableslist" });
    return import(/* webpackChunkName: "mod-roundtableslist" */ './module.roundtableslist');
}
modules["mod-roundtableslist"] = roundTablesListModule;

export function roundTablesModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-roundtables" });
    return import(/* webpackChunkName: "mod-roundtables" */ './module.roundtables');
}
modules["mod-roundtables"] = roundTablesModule;

export function searchModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-search" });
    return import(/* webpackChunkName: "mod-search" */ './module.search');
}
modules["mod-search"] = searchModule;

export function surveysTeaserModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-surveys-teaser" });
    return import(/* webpackChunkName: "mod-surveys-teaser" */ './module.surveys.teaser');
}
modules["mod-surveys-teaser"] = surveysTeaserModule;

export function surveysModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-surveys" });
    return import(/* webpackChunkName: "mod-surveys" */ './module.surveys');
}
modules["mod-surveys"] = surveysModule;

export function messageWallModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-eventmessageswall" });
    return import(/* webpackChunkName: "mod-eventmessageswall" */ './module.eventmessageswall');
}
modules["mod-eventmessageswall"] = messageWallModule;

export function eventMessagesTeaserModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-eventmessages-teaser" });
    return import(/* webpackChunkName: "mod-eventmessages-teaser" */ './module.eventmessages.teaser');
}
modules["mod-eventmessages-teaser"] = eventMessagesTeaserModule;

export function eventMessagesModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-eventmessages" });
    return import(/* webpackChunkName: "mod-eventmessages" */ './module.eventmessages');
}
modules["mod-eventmessages"] = eventMessagesModule;

export function eventthemesModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-eventthemes" });
    return import(/* webpackChunkName: "mod-eventthemes" */ './module.eventthemes');
}
modules["mod-eventthemes"] = eventthemesModule;

export function journeysModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-journeys" });
    return import(/* webpackChunkName: "mod-journeys" */ './module.journeys');
}
modules["mod-journeys"] = journeysModule;

export function journeysTeaserModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-journeys-teaser" });
    return import(/* webpackChunkName: "mod-journeys-teaser" */ './module.journeys.teaser');
}
modules["mod-journeys-teaser"] = journeysTeaserModule;

export function exhibitorsModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-exhibitors" });
    return import(/* webpackChunkName: "mod-exhibitors" */ './module.exhibitors');
}
modules["mod-exhibitors"] = exhibitorsModule;

export function exhibitorsMeetingRoomsModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-exhibitors-meeting-rooms" });
    return import(/* webpackChunkName: "mod-exhibitors-meeting-rooms" */ './module.exhibitors.meetingrooms');
}
modules["mod-exhibitors-meeting-rooms"] = exhibitorsMeetingRoomsModule;

export function speakersModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-speakers" });
    return import(/* webpackChunkName: "mod-speakers" */ './module.speakers');
}
modules["mod-speakers"] = speakersModule;

export function sessionsModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-sessions" });
    return import(/* webpackChunkName: "mod-sessions" */ './module.sessions');
}
modules["mod-sessions"] = sessionsModule;

export function registrationModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-registration" });
    return import(/* webpackChunkName: "mod-registration" */ './module.registration');
}
modules["mod-registration"] = registrationModule;

export function orderRegistrationModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-event-orderregistration" });
    return import(/* webpackChunkName: "mod-event-orderregistration" */ './module.orderregistration');
}
modules["mod-event-orderregistration"] = orderRegistrationModule;

export function liveSessionModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-livesession" });
    return import(/* webpackChunkName: "mod-livesession" */ './module.livesessions');
}
modules["mod-livesession"] = liveSessionModule;

export function liveSessionTeaserModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-livesessionteaser" });
    return import(/* webpackChunkName: "mod-livesessionteaser" */ './module.livesessions.teaser');
}
modules["mod-livesessionteaser"] = liveSessionTeaserModule;

export function liveSessionWallModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-livesessionwall" });
    return import(/* webpackChunkName: "mod-livesessionwall" */ './module.livesessionwall');
}
modules["mod-livesessionwall"] = liveSessionWallModule;

export function liveSessionBoardModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-livesessionboard" });
    return import(/* webpackChunkName: "mod-livesessionboard" */ './module.livesessionboard');
}
modules["mod-livesessionboard"] = liveSessionBoardModule;

export function partnerscanModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-partnerscan" });
    return import(/* webpackChunkName: "mod-partnerscan" */ './module.partnerscan');
}
modules["mod-partnerscan"] = partnerscanModule;

export function partnerworkspaceModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-partnerworkspace" });
    return import(/* webpackChunkName: "mod-partnerworkspace" */ '@@partnerworkspace/module.partnerworkspace');
}
modules["mod-partnerworkspace"] = partnerworkspaceModule;

export function speakerworkspaceModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({type: "MODULE", payload: "mod-speakerworkspace"});
    return import ('./speakerworkspace/module.speakerworkspace');
}

modules["mod-speakerworkspace"] = speakerworkspaceModule;

export function userworkspaceModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({type: "MODULE", payload: "mod-userworkspace"});
    return import ('./userworkspace/module.userworkspace');
}

modules["mod-userworkspace"] = userworkspaceModule;

export function eventAgendaModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-eventagenda" });
    return import(/* webpackChunkName: "mod-eventagenda" */ './module.eventagenda');
}
modules["mod-eventagenda"] = eventAgendaModule;

export function eventAgendaMeetingsModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-agenda-meetings" });
    return import(/* webpackChunkName: "mod-agenda-meetings" */ './module.agendameetings.teaser');
}
modules["mod-agenda-meetings"] = eventAgendaMeetingsModule;

export function eventAgendaMeetingRequestModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-agenda-meeting-request" });
    return import(/* webpackChunkName: "mod-agenda-meeting-request" */ './module.agendameetingrequest');
}
modules["mod-agenda-meeting-request"] = eventAgendaMeetingRequestModule;

export function sessionsOnlineModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-sessions-online" });
    return import(/* webpackChunkName: "mod-sessions-online" */ './module.sessions.online');
}
modules["mod-sessions-online"] = sessionsOnlineModule;

export function userModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-user" });
    return import(/* webpackChunkName: "mod-user" */ './module.user');
}
modules["mod-user"] = userModule;

export function sessionActionsModule(dispatch: (action) => void) {
    if (__SERVERSIDE__ && dispatch) {
        dispatch({ type: "MODULE", payload: "mod-sessions-actions" });
    }
    return import(/* webpackChunkName: "mod-sessions-actions" */ '@@event/features/module.actions.sessions');
}

export function partnerActionsModule(dispatch: (action) => void) {
    if (__SERVERSIDE__ && dispatch) {
        dispatch({ type: "MODULE", payload: "mod-partneractions" });
    }
    return import(/* webpackChunkName: "mod-partneractions" */ '@@event/features/module.actions.exhibitor');
}

export function offeringActionsModule(dispatch: (action) => void) {
    if (__SERVERSIDE__ && dispatch) {
        dispatch({ type: "MODULE", payload: "mod-offeringactions" });
    }
    return import(/* webpackChunkName: "mod-offeringactions" */ '@@event/features/module.actions.offerings');
}

export function eventThemeActionsModule(dispatch: (action) => void) {
    if (__SERVERSIDE__ && dispatch) {
        dispatch({ type: "MODULE", payload: "mod-eventthemeactions" });
    }
    return import(/* webpackChunkName: "mod-eventthemeactions" */ '@@event/features/module.actions.eventtheme');
}

export function eventCopilotModule(dispatch: (action) => void) {
    if (__SERVERSIDE__ && dispatch) {
        dispatch({ type: "MODULE", payload: "mod-eventcopilot" });
    }
    return import(/* webpackChunkName: "mod-eventcopilot" */ '@@event/features/module.eventcopilot');
}
modules["mod-eventcopilot"] = eventCopilotModule;

export function initEvent() {
    if (asyncBlocs["teaserofferings"]) {
        return;
    }

    registerFilterComponent("timeslotdays", TimeSlotDaysFilter);
    registerFilterComponent("timeslothour", TimeSlotHourFilter);

    asyncBlocs["teaserofferings"] = "mod-offering-teasers";
    asyncBlocs["offeringslist"] = "mod-offerings";
    asyncBlocs["teasersessions"] = "mod-session-teasers";
    asyncBlocs["teasersessionsbydays"] = "mod-session-teasers";
    asyncBlocs["offeringslist"] = "mod-offerings";
    asyncBlocs["partnerworkspaceteaser"] = "mod-control-partnerworkspaceteaser";
    asyncBlocs["teaserrooms"] = "mod-room-teasers";
    asyncBlocs["teaserexhibitorsteammembers"] = "mod-exhibitors-team-members-teasers";
    asyncBlocs["teaserexhibitorsmeetingrooms"] = "mod-exhibitors-meeting-rooms-teasers";
    asyncBlocs["teaserexhibitors"] = "mod-exhibitor-teasers";
    asyncBlocs["teaserspeakers"] = "mod-speaker-teasers";
    asyncBlocs["thematictrails"] = "mod-thematictrails";
    asyncBlocs["teasersearch"] = "mod-searchteaser";
    asyncBlocs["roundtableslist"] = "mod-roundtableslist";
    asyncBlocs["teaserroundtables"] = "mod-roundtables";
    asyncBlocs["teaserroundtablehalls"] = "mod-roundtables";
    asyncBlocs["roundtablesidebar"] = "mod-roundtables";
    asyncBlocs["globalsearch"] = "mod-search";
    asyncBlocs["teasersurvey"] = "mod-surveys-teaser";
    asyncBlocs["survey"] = "mod-surveys";
    asyncBlocs["eventmessageswall"] = "mod-eventmessageswall";
    asyncBlocs["alleventmessages"] = "mod-eventmessages-old"; // deprecated
    asyncBlocs["teasereventmessages"] = "mod-eventmessages-teaser";
    asyncBlocs["sendeventmessage"] = "mod-eventmessages-teaser";
    asyncBlocs["eventmessageslist"] = "mod-eventmessages";
    asyncBlocs["teasereventthemes"] = "mod-eventthemes";
    asyncBlocs["menueventthemes"] = "mod-eventthemes";
    asyncBlocs["teaserjourneys"] = "mod-journeys-teaser";
    asyncBlocs["allexhibitors"] = "mod-exhibitors-old"; // deprecated
    asyncBlocs["exhibitorslist"] = "mod-exhibitors";
    asyncBlocs["exhibitorsmeetingroomslist"] = "mod-exhibitors-meeting-rooms";
    asyncBlocs["allspeakers"] = "mod-speakers-old"; // deprecated
    asyncBlocs["speakerslist"] = "mod-speakers";
    asyncBlocs["allsessions"] = "mod-sessions-old"; // deprecated
    asyncBlocs["sessionslist"] = "mod-sessions";
    asyncBlocs["recommendedsessions"] = "mod-sessions";
    asyncBlocs["sendsessionmessage"] = "mod-sessions";
    asyncBlocs["multistepregistration"] = "mod-registration";
    asyncBlocs["invitedbydetail"] = "mod-registration";
    asyncBlocs["teaserlivesession"] = "mod-livesessionteaser";
    asyncBlocs["eventagenda"] = "mod-eventagenda";
    asyncBlocs["teaseragendameetings"] = "mod-agenda-meetings";
    asyncBlocs["teaseragendameetingrequest"] = "mod-agenda-meeting-request";
    asyncBlocs["teaserlogin"] = "mod-user";
    asyncBlocs["event-orderregistration"] = "mod-event-orderregistration";
    asyncBlocs["journeyslist"] = "mod-journeys";
    asyncBlocs["eventcopilot"] = "mod-eventcopilot";
    asyncBlocs["eventcopilotsearchbar"] = "mod-eventcopilot";

    registerAsyncItemTemplatePart("entityactions.exhibitor", (n, d, s) => exhibitorTeasersModule(d, s));
    registerAsyncItemTemplatePart("entitynavigationactions.exhibitor", (n, d, s) => exhibitorTeasersModule(d, s));
    registerAsyncItemTemplatePart("exhibitoritemeventthemes", (n, d, s) => exhibitorTeasersModule(d, s));
    registerAsyncItemTemplatePart("exhibitoritemaction", (n, d, s) => exhibitorTeasersModule(d, s));
    registerAsyncItemTemplatePart("offeringitemeventthemes", (n, d, s) => offeringTeasersModule(d, s));
    registerAsyncItemTemplatePart("offeringitemexhibitor", (n, d, s) => offeringTeasersModule(d, s));
    registerAsyncItemTemplatePart("offeringitemaction", (n, d, s) => offeringTeasersModule(d, s));
    registerAsyncItemTemplatePart("entityactions.session", (n, d, s) => sessionTeasersModule(d, s));
    registerAsyncItemTemplatePart("sessionitemaction", (n, d, s) => sessionTeasersModule(d, s));
    registerAsyncItemTemplatePart("sessionitemspeakers", (n, d, s) => sessionTeasersModule(d, s));
    registerAsyncItemTemplatePart("sessionitemeventthemes", (n, d, s) => sessionTeasersModule(d, s));
    registerAsyncItemTemplatePart("sessionitemexhibitors", (n, d, s) => sessionTeasersModule(d, s));
    registerAsyncItemTemplatePart("sessionmessageitemaction", (n, d, s) => sessionTeasersModule(d, s));
    registerAsyncItemTemplatePart("eventcountdown", (n, d, s) => eventCountdownModule(d, s));
    registerAsyncItemTemplatePart("roundtableitemexhibitors", (n, d, s) => roundTablesModule(d, s));
    registerAsyncItemTemplatePart("roundtableparticipants", (n, d, s) => roundTablesModule(d, s));
    registerAsyncItemTemplatePart("roundtableitemaction", (n, d, s) => roundTablesModule(d, s));
    registerAsyncItemTemplatePart("eventmessageitemaction", (n, d, s) => eventMessagesTeaserModule(d, s));
    registerAsyncItemTemplatePart("eventmessagetext", (n, d, s) => eventMessagesTeaserModule(d, s));
    registerAsyncItemTemplatePart("eventmessageitemaction", (n, d, s) => eventMessagesTeaserModule(d, s));
    registerAsyncItemTemplatePart("eventthemes", (n, d, s) => eventthemesModule(d, s));
    registerAsyncItemTemplatePart("eventthemespreferenceskeys", (n, d, s) => eventthemesModule(d, s));
    registerAsyncItemTemplatePart("teaserjourneys", (n, d, s) => journeysTeaserModule(d, s));
    registerAsyncItemTemplatePart("sessiononlinevideo", (n, d, s) => sessionsOnlineModule(d, s));
    registerAsyncItemTemplatePart("agendaitempersons", (n, d, s) => userModule(d, s));
    registerAsyncItemTemplatePart("agendaitemaction", (n, d, s) => userModule(d, s));

    registerAsyncEntityAction("registertosession", "mod-sessions-actions");
    registerAsyncEntityAction("unregistertosession", "mod-sessions-actions");
    registerAsyncEntityAction("bookmarksession", "mod-sessions-actions");
    registerAsyncEntityAction("unregistertosession", "mod-sessions-actions");
    registerAsyncEntityAction("unbookmarksession", "mod-sessions-actions");
    registerAsyncEntityAction("evaluatesession", "mod-sessions-actions");
    registerAsyncEntityAction("addpartnertofavorite", "mod-partneractions");
    registerAsyncEntityAction("removepartnerfromfavorite", "mod-partneractions");
    registerAsyncEntityAction("contactpartner", "mod-partneractions");
    registerAsyncEntityAction("meetpartner", "mod-partneractions");
    registerAsyncEntityAction("shareinfospartner", "mod-partneractions");
    registerAsyncEntityAction("addofferingtofavorite", "mod-offeringactions");
    registerAsyncEntityAction("removeofferingfromfavorite", "mod-offeringactions");

    registerAsyncEntityAction("addeventthemetofavorite", "mod-eventthemeactions");
    registerAsyncEntityAction("removeeventthemefromfavorite", "mod-eventthemeactions");

    registerAsyncActionModule("mod-sessions-actions", sessionActionsModule);
    registerAsyncActionModule("mod-partneractions", partnerActionsModule);
    registerAsyncActionModule("mod-offeringactions", offeringActionsModule);

    registerAsyncActionModule("mod-eventthemeactions", eventThemeActionsModule);
}
