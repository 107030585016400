import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { getBlocTemplate } from '@@services/itemshelpers';
import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { States } from '@@services/services';
import type { IDynamicBlocWithProps } from '@@pages/common';
import { TeaserItems } from '@@pages/teaseritems';
import { membersQuery } from '@@community/api/members';
import type { ITeaserItemsBlocCommonProperties } from '@@pages/teaseritems.props';
import type { IMember, ICommunityWebinar } from '@@community/data';
import { networkingActions } from '@@community/features/networking/actions';
import type { IInwinkEntityQueryResult, IInwinkEntityV3QueryOptions } from '@@api/front';
import { getEmptyMessage } from '@@modules/community/features/bloc.teaser.helper';
import { ICommunityRequestManager } from '@@services/apiaccessprovider.definition';
import { templatedString } from '@inwink/utils/methods';

export interface IBlocTeaserMembersProps
    extends IDynamicBlocWithProps<IBlocTeaserMembersBlocState, IBlocTeaserMembersBlocProperties> {
    networkingActions: typeof networkingActions;
    i18nHelper: Entities.i18nHelper;
    membersProvider?: (requestManager: ICommunityRequestManager,
        options?: IInwinkEntityV3QueryOptions,) => Promise<IInwinkEntityQueryResult<IMember>>;
    showSeeMoreModal?: boolean;
    seeMoreSearchPlaceholder?: string;
    onCloseSeeMoreModal?: () => void;
    seeMoreSearchFilter?: (item, searchTearm: string) => boolean;
    seeMoreItemsProvider?: () => Promise<any[]>;
}

export interface IBlocTeaserMembersBlocState {
    members: IMember[];
    itemtemplate: VisualTheme.IItemTemplate;
    loading: boolean;
}

export interface IBlocTeaserMembersBlocProperties extends ITeaserItemsBlocCommonProperties {
    targetPage: any;
    fromWebinar?: boolean;
}

export function blocTeaserMembersData(
    page: States.ICurrentPageState,
    statedata: IBlocTeaserMembersBlocState,
    blc: VisualTheme.IBlocContentTemplateBase,
    state: States.IAppState
) {
    const blocdata = statedata;
    const bloc = blc;
    const itemtemplate = getBlocTemplate(state.community.data, bloc);
    blocdata.itemtemplate = itemtemplate;
    blocdata.loading = true;
}

@withI18nHelper()
@connectwith(null, (dispatch) => {
    return {
        networkingActions: bindActionCreators(networkingActions, dispatch)
    };
})
export class BlocTeaserMembers extends React.PureComponent<IBlocTeaserMembersProps, any> {
    componentDidMount() {
        if (this.props.template.properties?.fromWebinar) {
            const newBlocState = { ...this.props.blocState };
            const webinar: ICommunityWebinar = this.props.datacontext.entity;
            // const speakers = webinar?.speakers?.map((m) => m.member);
            newBlocState.members = webinar?.speakers;
            newBlocState.loading = false;
            if (!this.props.blocState?.itemtemplate) {
                newBlocState.itemtemplate = getBlocTemplate(this.props.community.data, this.props.template);
            }
            this.props.update(newBlocState);
        } else {
            const nbItems = this.props.template?.properties?.maxItems || 6;
            const expression: any = {};
            const { page } = this.props;
            const pageEntityKind = page?.context?.entityKind;
            const pageEntityId = page?.context?.entityid;
            if (pageEntityKind === "Company" && pageEntityId) {
                expression.companyId = {
                    $eq: pageEntityId
                };
            }
            const query: IInwinkEntityV3QueryOptions = {
                selects: {
                    $all: true
                },
                page: {
                    size: nbItems,
                    index: 0
                },
                order: [
                    {
                        by: (this.props.template?.properties?.sort as any)?.propName || "createdAt",
                        desc: (this.props.template?.properties?.sort as any)?.order === "desc"
                    }
                ],
                contacts: true,
                filters: expression,
                expression: this.props.template.properties.filterexpr,
                allowSelf: true
            };
            (this.props.membersProvider ?? membersQuery)(
                this.props.community.requestManagers.apiFront,
                query
            ).then((res) => {
                const newBlocState = { ...this.props.blocState };
                newBlocState.members = res.data;
                newBlocState.loading = false;
                if (!this.props.blocState?.itemtemplate) {
                    newBlocState.itemtemplate = getBlocTemplate(this.props.community.data, this.props.template);
                }
                this.props.update(newBlocState);
            }).then(null, (err) => {
                this.props.update({ loading: false });
                console.error("error getting members", err);
            });
        }
    }

    memberClicked = (member, arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        
        if (!this.props.template.properties.disableNavigation) {
            this.props.networkingActions.showMember(
                this.props.i18nHelper,
                arg.currentTarget,
                member.id
            );
        }
    };

    getMemberUrl = (member: IMember) => {
        const { properties } = this.props.template;
    
        if (properties?.targetPage) {
            return this.props.urlservice.pageUrl(
                templatedString(properties.targetPage, { ...this.props.datacontext, entity: member })
            );
        }
        return this.props.urlservice.pageUrl(`members/${member.id}`);
    };

    render() {
        // Pour tester si le bloc utilise le nouveau gabarit avec bouton intéractif
        const newMemberTemplate = this.props.blocState?.itemtemplate?.template?.blocs?.some(
            e => e.type === "membercontactactions");
        const itemsToShow = this.props.template?.properties?.maxItems ? this.props.template.properties.maxItems : 4;

        const { blocState, i18n } = this.props;
        const { properties } = this.props.template;

        return <TeaserItems
            {...this.props}
            className="teaser-members"
            animateItems={true}
            loading={blocState?.loading}
            defaultItemsLayout="col3"
            items={blocState?.members}
            onItemClick={
                (this.props.template.properties.disableNavigation && !newMemberTemplate)
                    ? this.memberClicked
                    : null}
            linkto={
                !properties.disableNavigation ? this.getMemberUrl : null
            }
            allowEmpty={properties?.allowEmpty}
            itemtemplate={blocState?.itemtemplate}
            itemsPreviewCount={itemsToShow}
            emptyMessage={getEmptyMessage(i18n, properties)}
        />;
    }
}
