/* eslint-disable react/prefer-stateless-function */
import * as React from "react";
import { States } from '@@services/services';
import type { IAppHeaderContentProps } from "@@components/appheader/appheader.props";
import { connect } from 'react-redux';
import { AppHeaderAvatar } from './avatar';
import { AppHeaderChat } from './chat';
import { AppHeaderNotifications } from './notifications';
import { hasValidMembershipLevel } from "@@modules/community/data";
import { AppHeaderCommunityCopilot } from "./communitycopilot";
import { canShowCopilot } from "@@services/itemshelpers";
import { Entities } from "@inwink/entities/entities";
import { withI18nHelper } from "@inwink/i18n";

export interface IAppHeaderCommunityProps extends IAppHeaderContentProps {
    community?: States.ICommunityState;
    i18nHelper?: Entities.i18nHelper;
}

class AppHeaderCommunityComponent extends React.Component<IAppHeaderCommunityProps, any> {
    render() {
        const member = this.props.user?.currentUser?.member;
        const networking = member?.membershipLevel?.configuration?.isModerator
            || member?.membershipLevel?.configuration?.networking?.allowed;
        const { membershipIsValid } = hasValidMembershipLevel(this.props.user?.currentUser?.member);
        
        const copilotConf = this.props.copilotConfiguration;
        const allowCopilot = copilotConf?.enabled == true
            && copilotConf?.display?.appbar?.enabled == true
            && canShowCopilot(copilotConf, this.props);

        return <>
            {member && membershipIsValid && networking ? <AppHeaderChat {...this.props} /> : null}
            {member ? <AppHeaderNotifications {...this.props} /> : null}
            {allowCopilot ? <AppHeaderCommunityCopilot i18nHelper={this.props.i18nHelper} /> : null}
            <AppHeaderAvatar {...this.props} />
        </>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        i18n: state.i18n,
        user: state.user,
        community: state.community,
        rootwebsite: state.rootwebsite,
        pages: state.pages,
        // appMetaData: state.appMetaData,
        userChat: state.userChat,
        page: state.pages.currentPage
    };
}

export const AppHeaderCommunity: new (props: IAppHeaderCommunityProps)
=> React.Component<IAppHeaderCommunityProps, any> = connect(
    mapStateToProps,
    null
)(withI18nHelper()(AppHeaderCommunityComponent as any)) as any;
