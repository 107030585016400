import * as moment from 'moment';
import { parse, stringify } from '@inwink/utils/querystring';
import { setPopoverGlobalErrorHandler } from '@inwink/modals/errorhandling';
import { initRealtimeService } from './services/realtimeservice';
import { initActivityTracking } from './services/activityservice';
import { configureStore } from './store';
import { actions as i18nActions } from './services/i18nservice';
import { States } from './services/services';
import { initCommonBlocs } from './components/dynamicpage/blocscatalog.common';
import { setOnunauthenticated } from './services/apiaccessprovider';
import type { IRequestManager } from './services/apiaccessprovider.definition';
import { eventUserBootstrapModule } from './routes/appmodules';
import { initActionQueue } from './actionsqueue';
import { trackError, TrackingShardType } from '@@api/front/tracking.error';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import { pouet } from './chunkshook';

// if (pouet) {
//     pouet.push("");
// }

(global as any).moment = moment;

export function appStartup(history, config: _InwinkConfig, initialState: States.IAppState) {
    if (console && console.timeStamp) {
        console.timeStamp('client init');
    }
    const store = configureStore(initialState);
    initCommonBlocs();
    initRealtimeService(config.realtime, store);
    initActivityTracking(store);
    initActionQueue(store);

    // Si l'utilisateur courant se prend des 401, on force le délog
    setOnunauthenticated(() => {
        const state: States.IAppState = store.getState();
        if (state.user.currentUser) {
            eventUserBootstrapModule().then((mod) => {
                if (inwink.config.authMode === "firstpartycookies") {
                    mod.userBootstrapActions.clearCurrentUser()(store.dispatch, store.getState);
                } else {
                    mod.userBootstrapActions.logout()(store.dispatch, store.getState);
                }
            });
        }
    });

    // var routes;
    // if (startupWebsiteId) {
    //     routes = createRootWebsiteRoutes(store);
    // }
    // else if (startupEventId) {
    //     routes = createRoutesForSingleEvent(store);
    // }
    // else {
    //     routes = createRoutes(store);
    // }
    setTimeout(() => {
        import("./services/injectToGlobalScope").then((mod) => mod.injectToGlobalScope(store, history));
    }, 3000);

    if (!initialState || !initialState.i18n || !initialState.i18n.currentLanguage) {
        i18nActions.autoinit()(store.dispatch, store.getState);
    }

    let utmPersistenceKey = null;

    if (initialState.event?.eventid) {
        utmPersistenceKey = initialState.event?.eventid;
    } else if (initialState.community?.communityid) {
        utmPersistenceKey = initialState.community?.communityid;
    }

    if (history.location && history.location.search) {
        const args = parse(history.location.search);
        

        if (history.location.search.search('utm')) {
            global.inwink.utm = {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                utm_source: Array.isArray(args.utm_source) ? args.utm_source[0] : args.utm_source,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                utm_medium: Array.isArray(args.utm_medium) ? args.utm_medium[0] : args.utm_medium,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                utm_campaign: Array.isArray(args.utm_campaign) ? args.utm_campaign[0] : args.utm_campaign,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                utm_term: Array.isArray(args.utm_term) ? args.utm_term[0] : args.utm_term,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                utm_content: Array.isArray(args.utm_content) ? args.utm_content[0] : args.utm_content,
            };

            if (utmPersistenceKey) {
                try {
                    localStorage.setItem(`${utmPersistenceKey}_utm`, JSON.stringify({
                        ...global.inwink.utm,
                        createdAt: new Date().toISOString(),
                    }));
                } catch (error) {
                    console.error("error persisting utms", error);
                }
            }
        } 

        if (args.iw_invitationid || args.iw_exhibitorid || args.iw_passid) {
            global.inwink.invitation = {
                partnerInvitationId: args.iw_invitationid,
                exhibitor: args.iw_exhibitorid,
                passId: args.iw_passid
            };
        }

        if (args["data-info"] || args.sign) {
            global.inwink.registration = {
                dataInfo: args["data-info"],
                sign: args.sign
            };
        }
    }
    
    if (!global.inwink.utm && utmPersistenceKey) {
        try {
            const utmsString = localStorage.getItem(`${utmPersistenceKey}_utm`);
            if (utmsString) {
                const utms = JSON.parse(utmsString);
                const created = new Date(utms.createdAt);
                const limit = created.getTime() + (24 * 60 * 60 * 1000);
                if (Date.now() > limit) {
                    localStorage.removeItem(`${utmPersistenceKey}_utm`);     
                } else {
                    global.inwink.utm = utms;        
                }
                
            }
        } catch (error) {
            console.error("error parsing utms", error);
        }
    }

    setPopoverGlobalErrorHandler((error) => {
        const state = store && store.getState() as States.IAppState;
        let shardType: TrackingShardType;
        let shardId;
        let requestMgr: IRequestManager;
        if (state.event?.requestManagers?.apiFront) {
            requestMgr = state.event.requestManagers.apiFront;
            shardType = 'event';
            shardId = state.event.eventid;
        } else if (state.community?.requestManagers?.apiFront) {
            requestMgr = state.community.requestManagers.apiFront;
            shardType = 'community';
            shardId = state.community.communityid;
        } else if (state.rootwebsite?.requestManager?.apiFront) {
            requestMgr = state.rootwebsite.requestManager.apiFront;
            shardType = 'website';
            shardId = state.rootwebsite.websiteid;
        }
        if (requestMgr) {
            trackError(requestMgr, shardType, shardId, error);
        }
    });

    // trop d'erreurs parasites dues aux scripts custom :-(
    // window.addEventListener("error", (err: any) => {
    //     if (err && err.error) {
    //         var state = store && store.getState() as States.IAppState;
    //         trackError(state && state.event.eventid, err.error);
    //     }
    // });

    let previouslocation;
    history.listen((location) => {
        let currentlocation = location.pathname + location.search;
        // if(location.search && location.search.search("utm")) {
        //     global.inwink.config["utm"] = location.search
        // }

        if (!previouslocation || currentlocation !== previouslocation) {
            previouslocation = currentlocation;
            if (location.search) {
                const args = parse(location.search);
                if (args) {
                    const keys = Object.keys(args);
                    keys.forEach((k) => {
                        const lk = k.toLowerCase();
                        if (lk === 'firstname' || lk === 'lastname' || lk === 'mail' || lk === 'email'
                || lk === 'company' || lk === 'jobtitle' || lk === 'phone' || lk === 'telephone') {
                            delete args[k];
                        }
                    });
                    currentlocation = `${location.pathname}?${stringify(args)}`;
                }
            }
            // if(global.inwink.config && global.inwink.config.utm) {
            //     location.search = global.inwink.config.utm
            // }
            const interval = setInterval(() => {
                const state = store.getState();
                const pageTitle = state.pages?.currentPage?.title;
                if (pageTitle) {
                    import(/* webpackChunkName: "mod-trackingactions" */ "./services/tracking/actions").then((mod) => {
                        mod.tracker.trackPage(store.dispatch, store.getState, currentlocation, location, pageTitle);
                        clearInterval(interval);
                    });
                }
            }, 50);
        }
    // previouslocation = currentlocation;
    });

    if (navigator.userAgent.indexOf('iP') !== -1) { (window as any).document.body.classList.add('ios'); }
    if (navigator.userAgent.indexOf('Android') !== -1) { (window as any).document.body.classList.add('android'); }

    return {
    // routes: routes,
        store,
    // history: history
    };
}

export function whenDocumentReady() {
    return new Promise((resolve) => {
    // see if DOM is already available
        if (document.readyState === 'complete' || document.readyState === 'interactive') {
            // call on next available tick
            setTimeout(resolve, 1);
        } else {
            document.addEventListener('DOMContentLoaded', resolve);
        }
    });
}
