/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/dot-notation */
import { registerAsyncItemTemplatePart, registerItemTemplatePart } from '@inwink/itemtemplate/templatecatalog';
import type { States } from '@@services/services';
import { modules } from '@@routes/appmodules';
import { asyncBlocs } from '@@pages/blocscatalog';
import { registerAsyncEntityAction } from '@@pages/entitydetail/actionscatalog';
import { registerAsyncActionModule } from '@@pages/entitydetail/actions/actions.modules';
import { MemberBubbleTemplate } from '@@community/components/templates/memberbubble';

registerItemTemplatePart("memberbubble", MemberBubbleTemplate);

export function feedModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-feed" });
    return import(/* webpackChunkName: "mod-commu-feed" */ './features/module.feed');
}
modules["mod-commu-feed"] = feedModule;

export function publicationsModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-publications" });
    return import(/* webpackChunkName: "mod-commu-publications" */ './features/module.publications');
}
modules["mod-commu-publications"] = publicationsModule;

export function contentsModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-contents" });
    return import(/* webpackChunkName: "mod-commu-contents" */ './features/module.contents');
}
modules["mod-commu-contents"] = contentsModule;

export function contentsListModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-contentslist" });
    return import(/* webpackChunkName: "mod-commu-contentslist" */ './features/module.contents.list');
}
modules["mod-commu-contentslist"] = contentsListModule;

export function articleModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-content-article" });
    return import(/* webpackChunkName: "mod-commu-content-article" */ './features/module.content.article');
}
modules["mod-commu-content-article"] = articleModule;

export function documentModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-content-document" });
    return import(/* webpackChunkName: "mod-commu-content-document" */ './features/module.content.document');
}
modules["mod-commu-content-document"] = documentModule;

export function vodModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-content-vod" });
    return import(/* webpackChunkName: "mod-commu-content-vod" */ './features/module.content.vod');
}
modules["mod-commu-content-vod"] = vodModule;

export function podcastModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-content-podcast" });
    return import(/* webpackChunkName: "mod-commu-content-podcast" */ './features/module.content.podcast');
}
modules["mod-commu-content-podcast"] = podcastModule;

export function webinarModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-content-webinar" });
    return import(/* webpackChunkName: "mod-commu-content-webinar" */ './features/module.content.webinar');
}
modules["mod-commu-content-webinar"] = webinarModule;

export function registrationModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-registration" });
    return import(/* webpackChunkName: "mod-commu-registration" */ './features/module.registration');
}
modules["mod-commu-registration"] = registrationModule;

export function orderRegistrationModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-orderregistration" });
    return import(/* webpackChunkName: "mod-commu-orderregistration" */ './features/module.orderregistration');
}
modules["mod-commu-orderregistration"] = orderRegistrationModule;

export function membersListModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-memberslist" });
    return import(/* webpackChunkName: "mod-commu-memberslist" */ './features/module.memberlist');
}
modules["mod-commu-memberslist"] = membersListModule;


export function teaserMembersModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-teasermembers" });
    return import(/* webpackChunkName: "mod-commu-teasermembers" */ './features/module.teasermembers');
}
modules["mod-commu-teasermembers"] = teaserMembersModule;

export function memberModule(dispatch: (action) => void) {
    if (__SERVERSIDE__ && dispatch) {
        dispatch({ type: "MODULE", payload: "mod-commu-member" });
    }
    return import(/* webpackChunkName: "mod-commu-member" */ './features/module.member');
}

export function teaserContactRequestsModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-teasermembers" });
    return import(/* webpackChunkName: "mod-commu-teasercontactrequests" */ './features/module.teasercontactrequests');
}
modules["mod-commu-teasercontactrequests"] = teaserContactRequestsModule;

export function teaserRecommandationMembersModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-recommandationmembers" });
    return import(/* webpackChunkName: "mod-commu-recommandationmembers" */ './features/module.recommandationmembers');
}
modules["mod-commu-recommandationmembers"] = teaserRecommandationMembersModule;

export function companyModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-company" });
    return import(/* webpackChunkName: "mod-commu-company" */ './features/module.company');
}
modules["mod-commu-company"] = companyModule;

export function companyofferingModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-companyoffering" });
    return import(/* webpackChunkName: "mod-commu-companyoffering" */ './features/module.companyoffering');
}
modules["mod-commu-companyoffering"] = companyofferingModule;

export function forumModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-forum" });
    return import(/* webpackChunkName: "mod-commu-forum" */ './features/module.forum');
}
modules["mod-commu-forum"] = forumModule;

export function groupsListModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-groupslist" });
    return import(/* webpackChunkName: "mod-commu-groupslist" */ './features/module.groupslist');
}
modules["mod-commu-groupslist"] = groupsListModule;

export function teaserGroupsModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-teasergroups" });
    return import(/* webpackChunkName: "mod-commu-teasergroups" */ './features/module.teasergroups');
}
modules["mod-commu-teasergroups"] = teaserGroupsModule;

export function companyWorkspaceTeaserModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-companyworkspaceteaser" });
    return import(
        /* webpackChunkName: "mod-commu-companyworkspaceteaser" */
        '@@companyworkspace/companyworkspace.teaser'
    );
}
modules["mod-commu-companyworkspaceteaser"] = companyWorkspaceTeaserModule;

export function memberWorkspaceModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({type: "MODULE", payload: "mod-commu-memberworkspace"});
    return import ('@@memberworkspace/module.memberworkspace');
}

modules["mod-commu-memberworkspace"] = memberWorkspaceModule;

export function productModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-product" });
    return import(/* webpackChunkName: "mod-commu-product" */ './features/module.product');
}
modules["mod-commu-product"] = productModule;

export function exhibitorsTeamMembersTeasersModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-exhibitors-team-members-teasers" });
    return import( './features/module.exhibitorsTeamMembersTeasers');
}
modules["mod-commu-exhibitors-team-members-teasers"] = exhibitorsTeamMembersTeasersModule;

export function surveysModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-surveyscommunity" });
    return import(/* webpackChunkName: "mod-surveyscommunity" */ './features/module.surveyscommunity');
}
modules["mod-surveyscommunity"] = surveysModule;

export function copilotModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-commu-copilot" });
    return import(/* webpackChunkName: "mod-commu-copilot" */ './features/module.communitycopilot');
}
modules["mod-commu-copilot"] = copilotModule;

export function initCommunityBlocs() {
    asyncBlocs["commu-feed"] = "mod-commu-feed";
    asyncBlocs["commu-contentcomment"] = "mod-commu-contents";
    asyncBlocs["commu-spotlights"] = "mod-commu-contents";
    asyncBlocs["commu-userkpis"] = "mod-commu-contents";
    asyncBlocs["commu-teaserevents"] = "mod-commu-contents";
    asyncBlocs["commu-teasertopics"] = "mod-commu-contents";
    asyncBlocs["commu-teaserarticles"] = "mod-commu-contents";
    asyncBlocs["commu-teaservods"] = "mod-commu-contents";
    asyncBlocs["commu-teaserpodcasts"] = "mod-commu-contents";
    asyncBlocs["commu-teaserwebinars"] = "mod-commu-contents";
    asyncBlocs["commu-teaseriwevents"] = "mod-commu-contents";
    asyncBlocs["commu-teaserdocs"] = "mod-commu-contents";
    asyncBlocs["commu-topicslist"] = "mod-commu-contentslist";
    asyncBlocs["commu-articleslist"] = "mod-commu-contentslist";
    asyncBlocs["commu-vodslist"] = "mod-commu-contentslist";
    asyncBlocs["commu-podcastslist"] = "mod-commu-contentslist";
    asyncBlocs["commu-docslist"] = "mod-commu-contentslist";
    asyncBlocs["commu-webinarslist"] = "mod-commu-contentslist";
    asyncBlocs["commu-iweventslist"] = "mod-commu-contentslist";
    asyncBlocs["commu-teaserpublications"] = "mod-commu-publications";
    asyncBlocs["commu-memberslist"] = "mod-commu-memberslist";
    asyncBlocs["commu-companylist"] = "mod-commu-company";
    asyncBlocs["commu-teasercompany"] = "mod-commu-company";
    asyncBlocs["commu-companyofferinglist"] = "mod-commu-companyoffering";
    asyncBlocs["commu-teasercompanyoffering"] = "mod-commu-companyoffering";
    asyncBlocs["commu-groupslist"] = "mod-commu-groupslist";
    asyncBlocs["commu-teasergroups"] = "mod-commu-teasergroups";
    asyncBlocs["commu-multistepregistration"] = "mod-commu-registration";
    asyncBlocs["commu-teasermembers"] = "mod-commu-teasermembers";
    asyncBlocs["commu-teasercontactrequests"] = "mod-commu-teasercontactrequests";
    asyncBlocs["commu-recommandationmembers"] = "mod-commu-recommandationmembers";
    asyncBlocs["commu-teaserforums"] = "mod-commu-forum";
    asyncBlocs["commu-forumslist"] = "mod-commu-forum";
    asyncBlocs["commu-forumsubjectslist"] = "mod-commu-forum";
    asyncBlocs["commu-forumsubjectreplieslist"] = "mod-commu-forum";
    asyncBlocs["commu-teaserforumsubjects"] = "mod-commu-forum";
    asyncBlocs["commu-companyworkspaceteaser"] = "mod-commu-companyworkspaceteaser";
    asyncBlocs["commu-productlist"] = "mod-commu-product";
    asyncBlocs["commu-teaserproduct"] = "mod-commu-product";
    asyncBlocs["commu-teaserexhibitorsteammembers"] = "mod-commu-exhibitors-team-members-teasers";
    asyncBlocs["surveycommunity"] = "mod-surveyscommunity";
    asyncBlocs["commu-copilotsearchbar"] = "mod-commu-copilot";


    asyncBlocs["commu-orderregistration"] = "mod-commu-orderregistration";

    registerAsyncItemTemplatePart("contentstatus", (n, d, s) => contentsModule(d, s));
    registerAsyncItemTemplatePart("contentitemactions", (n, d, s) => contentsModule(d, s));
    registerAsyncItemTemplatePart("topicitemactions", (n, d, s) => contentsModule(d, s));
    registerAsyncItemTemplatePart("forumsubjectitemactions", (n, d, s) => contentsModule(d, s));
    registerAsyncItemTemplatePart("forumsubjectitemnavigation", (n, d, s) => contentsModule(d, s));
    registerAsyncItemTemplatePart("topics", (n, d, s) => contentsModule(d, s));
    registerAsyncItemTemplatePart("infolikes", (n, d, s) => contentsModule(d, s));
    registerAsyncItemTemplatePart("infocomments", (n, d, s) => contentsModule(d, s));
    registerAsyncItemTemplatePart("markdown", (n, d, s) => articleModule(d, s));
    registerAsyncItemTemplatePart("documentdownload", (n, d, s) => documentModule(d, s));
    registerAsyncItemTemplatePart("vodplayer", (n, d, s) => vodModule(d, s));
    registerAsyncItemTemplatePart("podcastplayer", (n, d, s) => podcastModule(d, s));
    registerAsyncItemTemplatePart("webinarplayer", (n, d, s) => webinarModule(d, s));
    registerAsyncItemTemplatePart("webinarlink", (n, d, s) => webinarModule(d, s));
    registerAsyncItemTemplatePart("commu-forumsubjectsearch", (n, d, s) => forumModule(d, s));
    registerAsyncItemTemplatePart("commu-forumsubjecttags", (n, d, s) => forumModule(d, s));
    registerAsyncItemTemplatePart("commu-forumsubjectcategories", (n, d, s) => forumModule(d, s));
    registerAsyncItemTemplatePart("commu-forumsubjectauthor", (n, d, s) => forumModule(d, s));
    registerAsyncItemTemplatePart("commu-forumsubjectlikes", (n, d, s) => forumModule(d, s));
    registerAsyncItemTemplatePart("commu-forumsubjectlastreply", (n, d, s) => forumModule(d, s));
    registerAsyncItemTemplatePart("membercontactactions", (n, d, s) => teaserMembersModule(d, s));
    registerAsyncItemTemplatePart("companyitem", (n, d, s) => contentsModule(d, s));
    registerAsyncItemTemplatePart("sponsorcontent", (n, d, s) => contentsModule(d, s));


    registerAsyncEntityAction("contentlike", "mod-commu-contents");
    registerAsyncEntityAction("likecontent", "mod-commu-contents");
    registerAsyncEntityAction("unlikecontent", "mod-commu-contents");
    registerAsyncEntityAction("contentbookmark", "mod-commu-contents");
    registerAsyncEntityAction("bookmarkcontent", "mod-commu-contents");
    registerAsyncEntityAction("unbookmarkcontent", "mod-commu-contents");
    registerAsyncEntityAction("registeriwevent", "mod-commu-contents");
    registerAsyncEntityAction("unregisteriwevent", "mod-commu-contents");
    registerAsyncEntityAction("eventwebsite", "mod-commu-contents");
    registerAsyncEntityAction("eventlocation", "mod-commu-contents");
    registerAsyncEntityAction("eventjoinwebinar", "mod-commu-contents");
    registerAsyncEntityAction("eventreplay", "mod-commu-contents");
    registerAsyncEntityAction("eventics", "mod-commu-contents");
    registerAsyncEntityAction("topicbookmark", "mod-commu-contents");
    registerAsyncEntityAction("bookmarktopic", "mod-commu-contents");
    registerAsyncEntityAction("unbookmarktopic", "mod-commu-contents");
    
    registerAsyncItemTemplatePart("companyitemactions", (n, d, s) => companyModule(d, s));
    registerAsyncEntityAction("companybookmark-toggle", "mod-commu-company");
    registerAsyncEntityAction("bookmarkcompany", "mod-commu-company");
    registerAsyncEntityAction("unbookmarkcompany", "mod-commu-company");
    registerAsyncEntityAction("companyemail", "mod-commu-company");

    registerAsyncItemTemplatePart("companyofferingitemactions", (n, d, s) => companyofferingModule(d, s));
    registerAsyncItemTemplatePart("companyofferingitem", (n, d, s) => companyofferingModule(d, s));
    registerAsyncEntityAction("companyofferingbookmark-toogle", "mod-commu-companyoffering");
    registerAsyncEntityAction("bookmarkcompanyoffering", "mod-commu-companyoffering");
    registerAsyncEntityAction("unbookmarkcompanyoffering", "mod-commu-companyoffering");
    registerAsyncEntityAction("companyofferingemail", "mod-commu-companyoffering");

    registerAsyncEntityAction("forumsubjectfollow", "mod-commu-forum");
    registerAsyncEntityAction("followforumsubject", "mod-commu-forum");
    registerAsyncEntityAction("unfollowforumsubject", "mod-commu-forum");
    registerAsyncEntityAction("forumsubjectlike", "mod-commu-forum");
    registerAsyncEntityAction("likeforumsubject", "mod-commu-forum");
    registerAsyncEntityAction("unlikeforumsubject", "mod-commu-forum");
    
    registerAsyncEntityAction("groupregistration", "mod-commu-contents");

    registerAsyncEntityAction("membercontactactions", "mod-commu-teasermembers");
    registerAsyncEntityAction("membercontact", "mod-commu-member");

    registerAsyncActionModule("mod-commu-content-article", articleModule);
    registerAsyncActionModule("mod-commu-contents", contentsModule);
    registerAsyncActionModule("mod-commu-company", companyModule);
    registerAsyncActionModule("mod-commu-companyoffering", companyofferingModule);
    registerAsyncActionModule("mod-commu-forum", forumModule);
    registerAsyncActionModule("mod-commu-member", memberModule);
}
