import * as React from 'react';
import * as moment from 'moment';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { Entities } from '@inwink/entities/entities';

import "./countdown.less";

export interface ICountDownProps {
    date: moment.Moment;
    baseClassName?: string;
    i18nHelper?: Entities.i18nHelper;
    onPassedDate?: () => void;
}

export interface ICountDownState {
    itemsProp?: any;
    currentTime?: moment.Moment;
    initialized: boolean;
}

@withI18nHelper()
export class CountDown extends React.Component<ICountDownProps, ICountDownState> {
    container = React.createRef<any>();

    interval = null;

    notified = false;

    constructor(props: ICountDownProps) {
        super(props);
        this.state = {
            currentTime: moment(),
            initialized: false
        };
    }

    componentDidMount() {
        if (!this.state.initialized) {
            this.setState({ initialized: true });
        }
        if (this.props.date) {
            this.interval = setInterval(this.checkCurrent, 1000);
            this.checkCurrent();
        }
    }

    checkCurrent = () => {
        const current = moment();

        if (!this.notified && current.isSameOrAfter(this.props.date, "seconds")) {
            this.notified = true;
            console.log("countdown expires");
            if (this.props.onPassedDate) {
                setTimeout(() => {
                    this.props.onPassedDate();
                }, 50);
            }
        }

        this.setState({ currentTime: current });
    };

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    renderEventCountDown = () => {
        const classNames = this.props.baseClassName || "iwcountdown";
        if (this.container?.current) {
            if (classNames !== this.container.current.className) {
                this.container.current.className = classNames;
            }
        }

        if (!this.state.initialized) {
            return <div className={classNames} ref={this.container}></div>;
        }

        const i18n = this.props.i18nHelper;
        const now = this.state.currentTime;

        // Real event date
        const startDate = moment(this.props.date);

        // If you wanna test with custom date
        // let startDate = moment("12/06/2018 18:15:00", "DD/MM/YYYY HH:mm:ss");
        // let endDate = moment("14/06/2018 19:00:00", "DD/MM/YYYY HH:mm:ss");

        const startMs = startDate.diff(now);

        const startDateDiffDays = Math.floor(startMs / 86400000);
        const startDateDiffHrs = Math.floor((startMs % 86400000) / 3600000);
        const startDateDiffMins = Math.floor(((startMs % 86400000) % 3600000) / 60000);
        const startDateDiffSec = Math.round((((startMs % 86400000) % 3600000) % 60000) / 1000);

        if (startDateDiffMins < 0 || startDateDiffHrs < 0 || startDateDiffDays < 0 || startDateDiffSec < 0) {
            return <div className="itemsbloccountdown countdown bloc-accent hidden">
                {/* <h2 className="bloctitle"><span className="text">{finish}</span></h2> */}
            </div>;
        }

        const secArray = startDateDiffSec.toString().split("");
        if (secArray.length === 1) {
            secArray[1] = secArray[0];
            secArray[0] = "0";
        } else if (secArray[0] === "6" && secArray[1] === "0") {
            secArray[1] = "0";
            secArray[0] = "0";
        }

        const minArray = startDateDiffMins.toString().split("");
        if (minArray.length === 1) {
            minArray[1] = minArray[0];
            minArray[0] = "0";
        } else if (minArray[0] === "6" && minArray[1] === "0") {
            minArray[1] = "0";
            minArray[0] = "0";
        }

        const hrsArray = startDateDiffHrs.toString().split("");
        if (hrsArray.length === 1) {
            hrsArray[1] = hrsArray[0];
            hrsArray[0] = "0";
        }

        const dayArray = startDateDiffDays.toString().split("");

        const secondes = <div className="bloc-accent numbers">
            <div>
                <p id="sd-0" className={"sd-" + secArray[0] === "sd-0" ? "active" : ""}>0</p>
                <p id="sd-1" className={"sd-" + secArray[0] === "sd-1" ? "active" : ""}>1</p>
                <p id="sd-2" className={"sd-" + secArray[0] === "sd-2" ? "active" : ""}>2</p>
                <p id="sd-3" className={"sd-" + secArray[0] === "sd-3" ? "active" : ""}>3</p>
                <p id="sd-4" className={"sd-" + secArray[0] === "sd-4" ? "active" : ""}>4</p>
                <p id="sd-5" className={"sd-" + secArray[0] === "sd-5" ? "active" : ""}>5</p>
                <p id="sd-6" className={"sd-" + secArray[0] === "sd-6" ? "active" : ""}>6</p>
            </div>
            <div>
                <p id="su-0" className={"su-" + secArray[1] === "su-0" ? "active" : ""}>0</p>
                <p id="su-1" className={"su-" + secArray[1] === "su-1" ? "active" : ""}>1</p>
                <p id="su-2" className={"su-" + secArray[1] === "su-2" ? "active" : ""}>2</p>
                <p id="su-3" className={"su-" + secArray[1] === "su-3" ? "active" : ""}>3</p>
                <p id="su-4" className={"su-" + secArray[1] === "su-4" ? "active" : ""}>4</p>
                <p id="su-5" className={"su-" + secArray[1] === "su-5" ? "active" : ""}>5</p>
                <p id="su-6" className={"su-" + secArray[1] === "su-6" ? "active" : ""}>6</p>
                <p id="su-7" className={"su-" + secArray[1] === "su-7" ? "active" : ""}>7</p>
                <p id="su-8" className={"su-" + secArray[1] === "su-8" ? "active" : ""}>8</p>
                <p id="su-9" className={"su-" + secArray[1] === "su-9" ? "active" : ""}>9</p>
            </div>
        </div>;

        const minutes = <div className="bloc-accent numbers">
            <div>
                <p id="md-0" className={"md-" + minArray[0] === "md-0" ? "active" : ""}>0</p>
                <p id="md-1" className={"md-" + minArray[0] === "md-1" ? "active" : ""}>1</p>
                <p id="md-2" className={"md-" + minArray[0] === "md-2" ? "active" : ""}>2</p>
                <p id="md-3" className={"md-" + minArray[0] === "md-3" ? "active" : ""}>3</p>
                <p id="md-4" className={"md-" + minArray[0] === "md-4" ? "active" : ""}>4</p>
                <p id="md-5" className={"md-" + minArray[0] === "md-5" ? "active" : ""}>5</p>
                <p id="md-6" className={"md-" + minArray[0] === "md-6" ? "active" : ""}>6</p>
            </div>
            <div>
                <p id="mu-0" className={"mu-" + minArray[1] === "mu-0" ? "active" : ""}>0</p>
                <p id="mu-1" className={"mu-" + minArray[1] === "mu-1" ? "active" : ""}>1</p>
                <p id="mu-2" className={"mu-" + minArray[1] === "mu-2" ? "active" : ""}>2</p>
                <p id="mu-3" className={"mu-" + minArray[1] === "mu-3" ? "active" : ""}>3</p>
                <p id="mu-4" className={"mu-" + minArray[1] === "mu-4" ? "active" : ""}>4</p>
                <p id="mu-5" className={"mu-" + minArray[1] === "mu-5" ? "active" : ""}>5</p>
                <p id="mu-6" className={"mu-" + minArray[1] === "mu-6" ? "active" : ""}>6</p>
                <p id="mu-7" className={"mu-" + minArray[1] === "mu-7" ? "active" : ""}>7</p>
                <p id="mu-8" className={"mu-" + minArray[1] === "mu-8" ? "active" : ""}>8</p>
                <p id="mu-9" className={"mu-" + minArray[1] === "mu-9" ? "active" : ""}>9</p>
            </div>
        </div>;

        const hours = <div className="bloc-accent numbers">
            <div>
                <p id="hd-0" className={"hd-" + hrsArray[0] === "hd-0" ? "active" : ""}>0</p>
                <p id="hd-1" className={"hd-" + hrsArray[0] === "hd-1" ? "active" : ""}>1</p>
                <p id="hd-2" className={"hd-" + hrsArray[0] === "hd-2" ? "active" : ""}>2</p>
            </div>
            <div>
                <p id="hu-0" className={"hu-" + hrsArray[1] === "hu-0" ? "active" : ""}>0</p>
                <p id="hu-1" className={"hu-" + hrsArray[1] === "hu-1" ? "active" : ""}>1</p>
                <p id="hu-2" className={"hu-" + hrsArray[1] === "hu-2" ? "active" : ""}>2</p>
                <p id="hu-3" className={"hu-" + hrsArray[1] === "hu-3" ? "active" : ""}>3</p>
                <p id="hu-4" className={"hu-" + hrsArray[1] === "hu-4" ? "active" : ""}>4</p>
                <p id="hu-5" className={"hu-" + hrsArray[1] === "hu-5" ? "active" : ""}>5</p>
                <p id="hu-6" className={"hu-" + hrsArray[1] === "hu-6" ? "active" : ""}>6</p>
                <p id="hu-7" className={"hu-" + hrsArray[1] === "hu-7" ? "active" : ""}>7</p>
                <p id="hu-8" className={"hu-" + hrsArray[1] === "hu-8" ? "active" : ""}>8</p>
                <p id="hu-9" className={"hu-" + hrsArray[1] === "hu-9" ? "active" : ""}>9</p>
            </div>
        </div>;

        let days;
        if (dayArray.length > 2) {
            days = <div className="bloc-accent numbers">
                <div>
                    <p id="de-0" className={"de-" + dayArray[0] === "de-0" ? "active" : ""}>0</p>
                    <p id="de-1" className={"de-" + dayArray[0] === "de-1" ? "active" : ""}>1</p>
                    <p id="de-2" className={"de-" + dayArray[0] === "de-2" ? "active" : ""}>2</p>
                    <p id="de-3" className={"de-" + dayArray[0] === "de-3" ? "active" : ""}>3</p>
                    <p id="de-4" className={"de-" + dayArray[0] === "de-4" ? "active" : ""}>4</p>
                    <p id="de-5" className={"de-" + dayArray[0] === "de-5" ? "active" : ""}>5</p>
                    <p id="de-6" className={"de-" + dayArray[0] === "de-6" ? "active" : ""}>6</p>
                    <p id="de-7" className={"de-" + dayArray[0] === "de-7" ? "active" : ""}>7</p>
                    <p id="de-8" className={"de-" + dayArray[0] === "de-8" ? "active" : ""}>8</p>
                    <p id="de-9" className={"de-" + dayArray[0] === "de-9" ? "active" : ""}>9</p>
                </div>
                <div>
                    <p id="dd-0" className={"dd-" + dayArray[1] === "dd-0" ? "active" : ""}>0</p>
                    <p id="dd-1" className={"dd-" + dayArray[1] === "dd-1" ? "active" : ""}>1</p>
                    <p id="dd-2" className={"dd-" + dayArray[1] === "dd-2" ? "active" : ""}>2</p>
                    <p id="dd-3" className={"dd-" + dayArray[1] === "dd-3" ? "active" : ""}>3</p>
                    <p id="dd-4" className={"dd-" + dayArray[1] === "dd-4" ? "active" : ""}>4</p>
                    <p id="dd-5" className={"dd-" + dayArray[1] === "dd-5" ? "active" : ""}>5</p>
                    <p id="dd-6" className={"dd-" + dayArray[1] === "dd-6" ? "active" : ""}>6</p>
                    <p id="dd-7" className={"dd-" + dayArray[1] === "dd-7" ? "active" : ""}>7</p>
                    <p id="dd-8" className={"dd-" + dayArray[1] === "dd-8" ? "active" : ""}>8</p>
                    <p id="dd-9" className={"dd-" + dayArray[1] === "dd-9" ? "active" : ""}>9</p>
                </div>
                <div>
                    <p id="du-0" className={"du-" + dayArray[2] === "du-0" ? "active" : ""}>0</p>
                    <p id="du-1" className={"du-" + dayArray[2] === "du-1" ? "active" : ""}>1</p>
                    <p id="du-2" className={"du-" + dayArray[2] === "du-2" ? "active" : ""}>2</p>
                    <p id="du-3" className={"du-" + dayArray[2] === "du-3" ? "active" : ""}>3</p>
                    <p id="du-4" className={"du-" + dayArray[2] === "du-4" ? "active" : ""}>4</p>
                    <p id="du-5" className={"du-" + dayArray[2] === "du-5" ? "active" : ""}>5</p>
                    <p id="du-6" className={"du-" + dayArray[2] === "du-6" ? "active" : ""}>6</p>
                    <p id="du-7" className={"du-" + dayArray[2] === "du-7" ? "active" : ""}>7</p>
                    <p id="du-8" className={"du-" + dayArray[2] === "du-8" ? "active" : ""}>8</p>
                    <p id="du-9" className={"du-" + dayArray[2] === "du-9" ? "active" : ""}>9</p>
                </div>
            </div>;
        } else if (dayArray.length > 1) {
            days = <div className="bloc-accent numbers">
                <div>
                    <p id="de-0" className={"de-" + dayArray[0] === "de-0" ? "active" : ""}>0</p>
                    <p id="de-1" className={"de-" + dayArray[0] === "de-1" ? "active" : ""}>1</p>
                    <p id="de-2" className={"de-" + dayArray[0] === "de-2" ? "active" : ""}>2</p>
                    <p id="de-3" className={"de-" + dayArray[0] === "de-3" ? "active" : ""}>3</p>
                    <p id="de-4" className={"de-" + dayArray[0] === "de-4" ? "active" : ""}>4</p>
                    <p id="de-5" className={"de-" + dayArray[0] === "de-5" ? "active" : ""}>5</p>
                    <p id="de-6" className={"de-" + dayArray[0] === "de-6" ? "active" : ""}>6</p>
                    <p id="de-7" className={"de-" + dayArray[0] === "de-7" ? "active" : ""}>7</p>
                    <p id="de-8" className={"de-" + dayArray[0] === "de-8" ? "active" : ""}>8</p>
                    <p id="de-9" className={"de-" + dayArray[0] === "de-9" ? "active" : ""}>9</p>
                </div>
                <div>
                    <p id="dd-0" className={"dd-" + dayArray[1] === "dd-0" ? "active" : ""}>0</p>
                    <p id="dd-1" className={"dd-" + dayArray[1] === "dd-1" ? "active" : ""}>1</p>
                    <p id="dd-2" className={"dd-" + dayArray[1] === "dd-2" ? "active" : ""}>2</p>
                    <p id="dd-3" className={"dd-" + dayArray[1] === "dd-3" ? "active" : ""}>3</p>
                    <p id="dd-4" className={"dd-" + dayArray[1] === "dd-4" ? "active" : ""}>4</p>
                    <p id="dd-5" className={"dd-" + dayArray[1] === "dd-5" ? "active" : ""}>5</p>
                    <p id="dd-6" className={"dd-" + dayArray[1] === "dd-6" ? "active" : ""}>6</p>
                    <p id="dd-7" className={"dd-" + dayArray[1] === "dd-7" ? "active" : ""}>7</p>
                    <p id="dd-8" className={"dd-" + dayArray[1] === "dd-8" ? "active" : ""}>8</p>
                    <p id="dd-9" className={"dd-" + dayArray[1] === "dd-9" ? "active" : ""}>9</p>
                </div>
            </div>;
        } else if (dayArray.length > 0) {
            days = <div className="bloc-accent numbers">
                <div>
                    <p id="de-0" className="active">0</p>
                </div>
                <div>
                    <p id="dd-0" className={"dd-" + dayArray[0] === "dd-0" ? "active" : ""}>0</p>
                    <p id="dd-1" className={"dd-" + dayArray[0] === "dd-1" ? "active" : ""}>1</p>
                    <p id="dd-2" className={"dd-" + dayArray[0] === "dd-2" ? "active" : ""}>2</p>
                    <p id="dd-3" className={"dd-" + dayArray[0] === "dd-3" ? "active" : ""}>3</p>
                    <p id="dd-4" className={"dd-" + dayArray[0] === "dd-4" ? "active" : ""}>4</p>
                    <p id="dd-5" className={"dd-" + dayArray[0] === "dd-5" ? "active" : ""}>5</p>
                    <p id="dd-6" className={"dd-" + dayArray[0] === "dd-6" ? "active" : ""}>6</p>
                    <p id="dd-7" className={"dd-" + dayArray[0] === "dd-7" ? "active" : ""}>7</p>
                    <p id="dd-8" className={"dd-" + dayArray[0] === "dd-8" ? "active" : ""}>8</p>
                    <p id="dd-9" className={"dd-" + dayArray[0] === "dd-9" ? "active" : ""}>9</p>
                </div>
            </div>;
        }

        const daysText = i18n.translate("countdown.days");
        const hoursText = i18n.translate("countdown.hours");
        const minutesText = i18n.translate("countdown.minutes");
        const secondesText = i18n.translate("countdown.secondes");
    
        return <div className={classNames} ref={this.container}>
            {(days ? <div className="countdown days">{days}<p>{daysText}</p></div> : null)}
            <div className="countdown hours">{hours}<p>{hoursText}</p></div>
            <div className="countdown minutes">{minutes}<p>{minutesText}</p></div>
            <div className="countdown secondes">{secondes}<p>{secondesText}</p></div>
        </div>;
    };

    render() {
        if (this.props.date) {
            return this.renderEventCountDown();
        }
        return null;
    }
}
