import * as assign from 'lodash/assign';
import { Entities } from '@inwink/entities/entities';
import { States } from './services';
import * as CommonUX from './uxactions';
import { metadataShareActions } from './appmetadataactions/share';

const appMetadataInitialState: States.IAppMetadataState = {
    origin: 'ukn',
    usersyncinprogress: false,
    eventsyncinprogress: false,
    communitysyncinprogress: false,
    rootwebsitesyncinprogress: false,
    showNotificationCenter: false,
    showMenu: false,
    loadMenu: false,
    bypassEventUrl: false,
    urls: {
        pageUrl: (event: Entities.IEventDetail, lngcode: string, url: string) => `/${event.tinyUrl}/` + url,
        contentUrl: (url: string) => {
            return (inwink.config.assetsUrl || '/') + url;
        }
    },
    preview: {
        edit: false
    }
};

export function appMetaDataReducer(
    state: States.IAppMetadataState = appMetadataInitialState,
    action = { type: '', payload: null }
) {
    switch (action.type) {
        case "MODULE": {
            let loadedModules = state.loadedModules ? state.loadedModules : [];
            if (!(loadedModules.indexOf(action.payload) >= 0)) {
                loadedModules = [...loadedModules];
                loadedModules.push(action.payload);
            }

            return assign({}, state, {
                loadedModules: loadedModules
            });
        }
        case "METADATA_ORIGIN": {
            return assign({}, state, {
                origin: action.payload
            });
        }
        case "METADATA_USERSYNC": {
            return assign({}, state, {
                usersyncinprogress: action.payload
            });
        }
        case "METADATA_EVENTSYNC": {
            return assign({}, state, {
                eventsyncinprogress: action.payload
            });
        }
        case "METADATA_COMMUNITYSYNC": {
            return assign({}, state, {
                communitysyncinprogress: action.payload
            });
        }
        case "METADATA_ROOTWEBSITESYNC": {
            return assign({}, state, {
                rootwebsitesyncinprogress: action.payload
            });
        }
        case "METADATA_NOTIFCENTER": {
            return assign({}, state, {
                showNotificationCenter: action.payload
            });
        }
        case "METADATA_MENU": {
            return assign({}, state, {
                showMenu: action.payload
            });
        }
        case "METADATA_ASSISTANT": {
            return assign({}, state, {
                showAssistant: action.payload.show,
                assistantQuestion: action.payload.question
            });
        }        
        case "METADATA_MENULOAD": {
            return assign({}, state, {
                loadMenu: true
            });
        }
        case "METADATA_PREVIEW": {
            return assign({}, state, {
                preview: assign({}, state.preview, action.payload)
            });
        }
        case "METADATA_LOGCONTEXT": {
            return assign({}, state, {
                logcontext: action.payload
            });
        }
        default:
            return state;
    }
}

CommonUX.registerGetShareCallback((i18n, dispatch, getState) => {
    return metadataShareActions.getShare(i18n)(dispatch, getState);
});
// CommonUX.registerNotifCenterCallback((show, dispatch, getState) => {
//     metadataActions.notifCenter(show)(dispatch, getState);
// });
