import { States } from "@@services/services";
import type { IGroupMember } from '../data';
import type { Entities } from "@inwink/entities/entities";

const INITIAL_STATE: States.ICommunityGroupState = {
    groupId: null,
    group: null,
    groupMember: null,
    groupMembers: []
};


export function communityGroupReducer(state: States.ICommunityGroupState = INITIAL_STATE, action = { type: '', payload: null }) {
    switch (action.type) {
        case "COMMUNITY_GROUP_SET": {
            return {
                ...state,
                groupId: action.payload.groupId,
                group: action.payload.group,
                groupMember: action.payload.groupMember,
                groupMembers: action.payload.groupMembers
            };
        }

        case "COMMUNITY_GROUPMEMBER_CHANGED": {
            const existingGroupMembers = state.group.groupMembers?.filter((gm) => gm.id !== action.payload.groupMember.id) ?? [];
            return {
                ...state,
                group: {
                    ...state.group,
                    groupMembers: [
                        ...existingGroupMembers,
                        action.payload.groupMember
                    ]
                }
            };
        }

        default:
            return state;
    }
}

export const communityGroupActions = {
    setCommunityGroup(group: Entities.ICommunityGroup, groupMember: IGroupMember) {
        return (dispatch) => {
            dispatch({
                type: "COMMUNITY_GROUP_SET",
                payload: {
                    group,
                    groupId: group.id,
                    groupMember
                }
            });
        };
    },

    communityGroupMemberChanged(groupMember: IGroupMember) {
        return (dispatch) => {
            dispatch({
                type: "COMMUNITY_GROUPMEMBER_CHANGED",
                payload: { groupMember }
            });
        };
    }
};
