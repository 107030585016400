import { defaultPostHeaderForJsonData } from 'api/front';
import type { IInwinkEntityV3QueryOptions } from '@@api/front/definitions';
import type { ICommunityRequestManager, ICommunityRequests } from '@@services/apiaccessprovider.definition';
import type { IMember } from '@@community/data';
import type { IFrontDatasourceV3, IInwinkEntityQueryResult } from '../../../api/front/datasource';
import type { Entities } from '@inwink/entities/entities';
import type { IOrderDatasourceCheckResult } from '@inwink/ticketing/definitions';

export function membersDatasourceV3(requestManager: ICommunityRequestManager,
    turl: string = null) : Promise<IFrontDatasourceV3<IMember>> {
    return import("../../../api/front/datasource").then((mod) => {
        const url = turl || (`community/${requestManager.communityId}/member`);
        return mod.getFrontDataSource<IMember>(
            requestManager, url
        );
    });
}

export function membersQuery(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions,
    groupId?: string,
    forumId?: string
) {
    const targetOptions = options || {};
    let url;
    if (groupId) {
        url = `community/${requestManager.communityId}/group/${groupId}/members`;
    }
    if (forumId) {
        url = `community/${requestManager.communityId}/forum/${forumId}/members`;
    }
    return membersDatasourceV3(requestManager, url).then((ds) => ds.query(targetOptions));
}

export function membersCount(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions
) {
    const targetOptions = options || {};
    return membersDatasourceV3(requestManager).then((ds) => ds.count(targetOptions));
}

export function pendingMembersQuery(
    requestManager: ICommunityRequestManager,
    groupId: string,
    options?: IInwinkEntityV3QueryOptions,
) {
    const targetOptions = options || {};
    const url = `community/${requestManager.communityId}/group/${groupId}/pendingmembers`;
    return membersDatasourceV3(requestManager, url).then((ds) => ds.query(targetOptions));
}

export function membersRecommendationQuery(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions
) {
    const targetOptions = options || {};
    return membersDatasourceV3(requestManager, `community/${requestManager.communityId}/member/recommendations`)
        .then((ds) => ds.query(targetOptions));
}

export function membersRecommendationCountQuery(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions
): Promise<number> {
    const targetOptions = options || {};
    return membersDatasourceV3(requestManager, `community/${requestManager.communityId}/member/recommendations`)
        .then((ds) => ds.count(targetOptions));
}

export function getTeamMembersForCompany(communityRequests: ICommunityRequests, companyId: string,
    options: IInwinkEntityV3QueryOptions): Promise<IInwinkEntityQueryResult<Entities.IPerson>> {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontCommunityMemberEntityDataSource<Entities.IPerson>(communityRequests.apiFront,
            `company/${companyId}/account`, "ExhibitorAccount").getAll(options);
    });
}

export function membershipRenew(requestManager: ICommunityRequestManager,
    communityId: string,
    renewProductId: string
) : Promise<IOrderDatasourceCheckResult>{
    return requestManager.postJson(
        "/community/" + communityId + "/member/membership/renew",
        JSON.stringify({
            renewProductId: renewProductId
        }),
        defaultPostHeaderForJsonData
    );
}