import { AppTextLabel } from "@@services/i18nservice";
import { InWinkInput } from "@inwink/commonui";
import { Entities } from "@inwink/entities/entities";
import React from "react";

import './communitycopilotsearchbar.less';

export function CommunityCopilotSearchBar(props: { i18nHelper: Entities.i18nHelper, onSearch: (search: string) => void }) {
    const searchInput = React.useRef<HTMLInputElement>(null);
    const search = () => {
        props.onSearch(searchInput?.current?.value ?? "");
        searchInput.current.value = "";
    };
    return <div className="communitycopilot-search-bar">
        <InWinkInput
            type="search"
            ref={searchInput}
            placeholder={props.i18nHelper.translate('communitycopilot.search.placeholder')}
            onKeyPress={(e) => {
                if (e.charCode === 13) search();
            }}
        />
        <button type="button" className="btnsearch" onClick={search}>
            <i className="inwink-search" />
            <AppTextLabel className="label" i18n="actions.search" />
        </button>
    </div>;
}