import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { pageAliases as sharedPageAliases } from '@inwink/react-utils/navigation/data';
import { getFrontTargetRoutePage as getEventFrontTargetRoutePage } from '@inwink/react-utils/navigation/getfronttargetroutepage';
import {
    getFrontTargetRoutePage as getCommunityFrontTargetRoutePage
} from '@inwink/react-utils/navigation/getfronttargetroutepage.community';
import * as moment from 'moment';
import type { IInwinkStore } from '@@store/index';
import { getTimeZoneDate } from './displaytimezonedate';
import { eventUserActionsModule, userMessageModule, userChatModule } from '../routes/appmodules';
import { metadataMenuActions } from '@@services/appmetadataactions/menu';
import { getCopilot } from '@@data/templates';
import { States } from '@@services/services';
import { canShowCopilot } from '@@services/itemshelpers';

export const pageAliases = sharedPageAliases;

export function getTargetPage(
    link: VisualTheme.IAppLink,
    context?: VisualTheme.IPageContext,
    store?: IInwinkStore,
    data?: any,
    router?: any,
    args?: string,
    urlservice?: any,
    i18nHelper?: Entities.i18nHelper,
    isAnchor?: boolean

): any {
    const state = store.getState();
    let getFrontTarget = getEventFrontTargetRoutePage;
    if (state.community?.communityid && !(state.event?.eventid)) {
        getFrontTarget = getCommunityFrontTargetRoutePage;
    }

    if (link.action === "login"
        || link.action === "loginFromContext"
        || link.target === "login"
        || link.target === "loginFromContext") {
        if (store) {
            const target = link.action || link.target;

            return () => {
                // const afterAuth;
                let customArgs;
                if (target === "loginFromContext") {
                    customArgs = data;
                }
                userMessageModule().then((mod) => {
                    mod.commonUxActions.showLogin(null, customArgs)(store.dispatch, store.getState);
                });
            };
        }
        return () => { };
    }
    if (link.action === "logout") {
        if (store) {
            return () => {
                userMessageModule().then((mod) => {
                    mod.commonUxActions.logout()(store.dispatch, store.getState);
                });
            };
        }
        return () => { };
    }
    if (link.action === "opencopilot") {
        if (store) {
            let copilotConfig: States.ICopilotConfiguration;
            if (state.event?.detail) {
                copilotConfig = getCopilot(state.event.data);

            } else if (state.community?.detail) {
                copilotConfig = getCopilot(state.community.data);
            }

            if (copilotConfig) {
                const show = canShowCopilot(copilotConfig,
                    { event: state.event, community: state.community, user: state.user, i18n: state.i18n });
                if (show) {
                    return () => {
                        metadataMenuActions.showAssistant(!store.getState().appMetaData.showAssistant)(store.dispatch);
                    };
                }
            }
        }
        return null;
    }
    if (link.action === "register") {
        if (store) {
            const eventConfig = state.event && state.event.detail && state.event.detail.configuration;
            if (eventConfig) {
                const defaultRegistrationPage = eventConfig.registration && eventConfig.registration.defaultRegistrationPage;
                if (eventConfig.registration && eventConfig.registration.defaultRegistrationLink) {
                    return getTargetPage(
                        eventConfig.registration?.defaultRegistrationLink,
                        context,
                        store,
                        data,
                        router,
                        args,
                        urlservice
                    );
                } if (defaultRegistrationPage) {
                    return defaultRegistrationPage;
                }
            }
        }
    } else if (link.action === "notifcenter") {
        if (store) {
            return () => {
                if (state.user && state.user.currentUser) {
                    import("../services/appmetadataactions/notifs").then((mod) => {
                        mod.metadataNotifsActions.notifCenter(true)(store.dispatch);
                    });
                }
            };
        }
    } else if (link.action === "gotonextsession") {
        let nextSession;
        if (state.event?.data?.sessions?.data?.length) {
            const currentDate = moment();
            const nextSessions = [];
            const sessions = state.event.data.sessions.data.filter((s) => s.timeslots?.length);
            if (sessions.length) {
                const OnlyPreviewSessions = !sessions.filter((s) => s.status?.toLowerCase() === "published").length;
                if (OnlyPreviewSessions) {
                    // Dans le cas où toutes les sessions ont un status preview.
                    return getFrontTarget(
                        {
                            target: "home"
                        }
                    );
                }
                sessions.map((s) => {
                    if (s.timeslots?.length) {
                        const timeslot = s.timeslots[0];
                        const endDate = getTimeZoneDate(timeslot.endDate, state.event?.detail, state.i18n);
                        if (moment(endDate).isAfter(currentDate) && s.status?.toLowerCase() === "published") {
                            nextSessions.push(s);
                        }
                    }
                    return true;
                });
                if (nextSessions.length) {
                    nextSessions.sort((a, b) => {
                        const aEndDate = getTimeZoneDate(a.timeslots[0].endDate, state.event?.detail, state.i18n);
                        const bEndDate = getTimeZoneDate(b.timeslots[0].endDate, state.event?.detail, state.i18n);
                        if (aEndDate.diff(currentDate) > bEndDate.diff(currentDate)) {
                            return 1;
                        } if (bEndDate.diff(currentDate) > aEndDate.diff(currentDate)) {
                            return -1;
                        }
                        return 0;
                    });
                    nextSession = nextSessions[0];
                } else {
                    // Dans le cas où toutes les sessions sont passées.
                    nextSession = sessions[sessions.length - 1];
                }
            } else {
                // Dans le cas où aucune session n'a de timeslots.
                nextSession = state.event.data.sessions.data[0];
            }
        }
        if (nextSession) {
            const res = getFrontTarget(
                {
                    target: "sessiondetail",
                    detail: [nextSession.id]
                }
            );
            return res;
        }
    } else if (link.action === "sessionsatisfaction") {
        if (store) {
            let session;
            if (state?.event?.data?.sessions && link?.detail?.length) {
                session = state.event.data.sessions.data.find((s) => s.id === link.detail[0]);
            }
            return (arg) => {
                eventUserActionsModule().then((mod) => {
                    mod.sessionActions.evaluate(arg, session, i18nHelper)(store.dispatch, store.getState);
                });
            };
        }
    } else if (link.action === "contactorganizer") {
        if (state.user?.currentUser) {
            return () => {
                userChatModule(store).then((mod) => {
                    mod.userChatActions.showOrganizerDiscussion()(store.dispatch);
                });
            };
        }
        return (arg) => {
            userMessageModule().then((mod) => {
                mod.loginActions.notifyRequireLogin(arg.target as HTMLElement, i18nHelper)(store.dispatch, store.getState);
            });
        };
    } else if (link.action === "showconsent") {
        return () => {
            if (state.tracking?.consentTracking?.showConsent) {
                state.tracking?.consentTracking?.showConsent();
            }
        };
    } else if (link.action === "gotocurrentcompany") {
        const companyId = router?.match?.params?.companyworkspaceid;
        const res = getFrontTarget(
            {
                target: "companydetail",
                detail: [companyId]
            }
        );
        return res;
    } else if (link.action === "gotocurrentexhibitor") {
        const exhibitorId = router?.match?.params?.exhibitorworkspaceid;
        const res = getFrontTarget(
            {
                target: "exhibitordetail",
                detail: [exhibitorId]
            }
        );

        return res;
    } else {
        const params = router?.route?.match?.params || router?.match?.params;
        let res = getFrontTarget(
            link,
            context,
            params,
            null,
            isAnchor,
            state.user?.currentUser?.detail?.id
        );

        if (args) {
            if (res.indexOf("?") > 0) {
                res = res + "&" + args;
            } else {
                res = res + "?" + args;
            }
        }
        return res;
    }
}

export function getEntityCustomFieldTargetLink(
    field: { name: string, type: string },
    entity: any,
    i18nHelper: Entities.i18nHelper
) {
    let url;
    if (field) {
        const data = entity && entity[field?.name];
        const fieldType = field?.type?.toLowerCase();

        if (data) {
            switch (fieldType) {
                case "image":
                    url = data.url;
                    break;
                case "file":
                    url = i18nHelper.translateBag(data)?.url ?? data.url;
                    break;
                default:
                    url = (typeof data === 'object' && data !== null && Object.keys(data).length)
                        ? i18nHelper.translateBag(data) : data; break;
            }
        }
    }

    if (!isValidUrl(url)) return null;

    return enforceHttps(url);
}

function isValidUrl(url) {
    const urlRegEx = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@%!$&'()*+,;=.]+$/);
    return urlRegEx.test(url);
}

function enforceHttps(url) {
    if (!url.startsWith("https://")) {
        if (url.startsWith("http://")) {
            return url.replace("http://", "https://");
        } else {
            return "https://" + url;
        }
    }
    return url;
}
