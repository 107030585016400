import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import type { States } from "@@services/services";
import { metadataMenuActions } from '@@services/appmetadataactions/menu';
import { userMessageActions } from '@@services/usermessageactions';
import { Entities } from '@inwink/entities/entities';
import { CommunityCopilotSearchBar } from '@@modules/community/features/communitycopilot/communitycopilotsearchbar';

import './communitycopilot.less';

export interface IAppHeaderCommunityCopilotProps {
    appMetaData?: States.IAppMetadataState;
    metadataMenuActions?: typeof metadataMenuActions;
    userMessageActions?: typeof userMessageActions;
    i18nHelper?: Entities.i18nHelper;
}

function AppHeaderCommunityCopilotComponent(props: IAppHeaderCommunityCopilotProps) {
    const openCopilot = (arg: React.MouseEvent) => {
        const elt = arg.currentTarget as HTMLElement;
        return (props.userMessageActions.showMessage(props.i18nHelper, AppHeaderCopilotSearchComponent,
            props, elt, null, null, "copilotsearchmodal") as any)
            .then((res) => {
                if (res != null && res != undefined) {
                    props.metadataMenuActions.showAssistant(true, res);
                }
            });
    };

    return (
        <div className="appheader-action-button appheader-copilot">
            <AsyncButton className="button chat-button" id="chat-button" onClick={openCopilot}>
                <i className="icon inwink-search" />
            </AsyncButton>
        </div>
    );
}

function mapStateToUserMessageProps(state: States.IAppState) {
    return {
        appMetaData: state.appMetaData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        metadataMenuActions: bindActionCreators(metadataMenuActions, dispatch),
        userMessageActions: bindActionCreators(userMessageActions, dispatch)
    };
}

export const AppHeaderCommunityCopilot: new (
    props: IAppHeaderCommunityCopilotProps
) => React.Component<IAppHeaderCommunityCopilotProps, any> = connect(
    mapStateToUserMessageProps,
    mapDispatchToProps
)(AppHeaderCommunityCopilotComponent as any) as any;

function AppHeaderCopilotSearchComponent(props: { i18nHelper: Entities.i18nHelper, onCompleted: (search: string) => void }) {
    return <CommunityCopilotSearchBar i18nHelper={props.i18nHelper} onSearch={props.onCompleted} />;
}