import { States } from '../../../../services/services';

export type IActionModuleInit = (dispatch, getState) => Promise<any>;
const modules: Record<string, IActionModuleInit> = {
};

export function registerAsyncActionModule(name: string, module: IActionModuleInit) {
    modules[name] = module;
}

export function loadEntityActionModule(name: string, dispatch: (action) => void, getState: () => States.IAppState): Promise<any> {
    const actionmodule = modules[name];
    if (actionmodule) {
        return actionmodule(dispatch, getState).then(() => {
            return Promise.resolve();
        });
    }
}

