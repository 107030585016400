import * as React from 'react';
import { Loader } from '@inwink/loader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logging } from '@inwink/logging';
import { withRouter } from 'react-router-dom';
// import { Entities } from '@inwink/entities/entities';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { ContentInjection } from '../contentinjection';
import { actions as pageActions, filterBlocs, filterBlocContent } from '../../services/pageservice';
import { blocsCatalog } from './blocscatalog';
import { AppTextLabel, AppLabel } from '../../services/i18nservice';
import { States } from '../../services/services';
import { DynamicPageSidebar } from './dynamicpagesidebar';
import { DynamicPageBloc } from './dynamicpagebloc';
import { DynamicPageBlocContent } from "./dynamicpagebloc.content";
import { DynamicPageMetaData, DynamicPageMicrodata } from './dynamicpagemetadata';
import { pageDataContext } from './dynamicpage.pagedatacontext';
import { IDynamicPageProps } from './dynamicpage.props';
import { withUrlService } from '../urlstatecontext';
import { AppUserRestrictedComponent } from '@@components/appuser.restricted';
import { isFromServer } from './helpers';

// -- trick pour incorporer ces éléments dans un chunk via webpack
import './teaseritems.less';

import './dynamicpage.less';

export type { IDynamicPageProps as DynamicPageProps } from './dynamicpage.props';

const logger = logging.getLogger('UI');

export interface IDynamicPageState {
    blocsToRender: number;
    blocsIncrement: number;
    blocsDelay: number;
    allowProgressiveRendering: boolean;
    // datacontext: Entities.IPageDataContext;
    pageLoading?: boolean;
    displayBackToTop?: boolean;
}

class DynamicPageComponent extends React.Component<IDynamicPageProps, IDynamicPageState> {
    pendingBlocUpdate: any;

    unmounted: boolean;

    pageTemplate: States.ICurrentPageState;

    location: any;

    scrollContainer = React.createRef<HTMLDivElement>();

    scrollToRestore: number;

    constructor(props: IDynamicPageProps) {
        super(props);

        const page = this.props.pages[this.props.template] as States.ICurrentPageState;
        this.location = props.location && JSON.stringify(props.location.pathname);

        this.state = {
            // datacontext: pageDataContext(props),
            blocsToRender: ((page?.template?.config?.blocsBatch?.initial) || 2),
            blocsIncrement: ((page?.template?.config?.blocsBatch?.increment) || 2),
            blocsDelay: 100,
            allowProgressiveRendering: !(isFromServer())
        };

        this.pageTemplate = page && page.template && page.template.config as any;
        if (props.history && props.history.action && props.history.action === 'POP') {
            this.scrollToRestore = page && page.restoreScroll;
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    routeParamsChanged(nextlocation, location) {
        if (nextlocation.pathname !== location.pathname) {
            return true;
        }

        if (nextlocation.search !== location.search) {
            return true;
        }

        if (nextlocation.params && nextlocation.params !== location.params) {
            const keys = Object.keys(nextlocation.params);
            return keys.some((k) => nextlocation.params[k] !== location.params[k]);
        }

        return false;
    }

    shouldComponentUpdate(nextprops: IDynamicPageProps, nextstate: IDynamicPageState) {
        const page = this.props.pages[this.props.template];
        const newpage = nextprops.pages[this.props.template];
        const pageTemplate = newpage && newpage.template && newpage.template.config;
        // const hasMultiStepRegistrationBloc = pageTemplate?.blocs?.length
        // && page?.template?.config?.blocs?.find((b) => b.type === "multistepregistration");

        if (nextstate.pageLoading !== this.state.pageLoading) {
            return true;
        }

        if (this.props.visualstate !== nextprops.visualstate) {
            logger.debug('update dynamic page ' + (page ? page.id : '') + ' because visualstate changed');
            return true;
        }

        if (nextprops.visualConfiguration !== this.props.visualConfiguration) {
            logger.debug('update dynamic page ' + (page ? page.id : '') + ' because visualconf changed');
            return true;
        }

        if (this.routeParamsChanged(nextprops.location, this.props.location)) {
            logger.debug('update dynamic page ' + (page ? page.id : '') + ' because location changed');
            return true;
        }

        if ((page?.pageargs?.match?.params) !== (newpage?.pageargs?.match?.params)) {
            return false;
        }

        if (newpage !== page || newpage.data !== page.data || (pageTemplate !== this.pageTemplate)) {
            logger.debug('update dynamic page ' + (page ? page.id : '') + ' because page changed');
            this.pageTemplate = pageTemplate;
            return true;
        }

        if (nextprops.user
            && nextprops.user.currentUser
            && ((!this.props.user.currentUser || nextprops.user.currentUser !== this.props.user.currentUser))
            // && !hasMultiStepRegistrationBloc
        ) {
            logger.debug('update dynamic page ' + (page ? page.id : '') + ' because user changed');
            return true;
        }

        if (nextstate.blocsToRender !== this.state.blocsToRender) {
            logger.verbose('update dynamic page ' + (page ? page.id : '') + ' because blocs to render');
            return true;
        }

        if (nextprops.event !== this.props.event) {
            logger.debug('update dynamic page ' + (page ? page.id : '') + ' because event changed');
            return true;
        }

        if (nextprops.community !== this.props.community) {
            logger.debug('update dynamic page ' + (page ? page.id : '') + ' because community changed');
            return true;
        }

        if (nextprops.i18n !== this.props.i18n) {
            logger.debug('update dynamic page ' + (page ? page.id : '') + ' because lng changed');
            return true;
        }

        if (nextstate.displayBackToTop !== this.state.displayBackToTop) {
            return true;
        }

        if (nextprops.currentpage !== this.props.currentpage) {
            logger.debug('update dynamic page ' + (page ? page.id : '') + ' because currentpage changed');
            return true;
        }

        logger.verbose('dynamic page : no re-render');
        return false;
    }

    pageInjection(page: States.ICurrentPageState) {
        const inject = page?.template?.config?.metadata?.inject;
        if (!inject) {
            return undefined;
        }

        return <ContentInjection id="pageinjection" inject={page.template.config.metadata.inject} />;
    }

    checkRemainingBlocs() {
        if (this.pendingBlocUpdate) {
            clearTimeout(this.pendingBlocUpdate);
            this.pendingBlocUpdate = null;
        }

        let totalBlocs;

        const page = this.props.pages[this.props.template] as States.ICurrentPageState;
        if (page && page.template && page.template.config && page.template.config.blocs) {
            totalBlocs = page.template.config.blocs.length;
        }
        // On prend en compte le footer global pour que le bon nombre de blocs soient générés.
        if (this.props.pagefooter && this.props.pagefooter.config && this.props.pagefooter.config.blocs) {
            totalBlocs += this.props.pagefooter.config.blocs.length;
        }

        if (page && this.state.allowProgressiveRendering && totalBlocs && this.state.blocsToRender < totalBlocs) {
            if (!this.unmounted) {
                this.pendingBlocUpdate = setTimeout(() => {
                    if (!this.unmounted) {
                        this.setState((currentState) => {
                            const newBlocsToRender = currentState.blocsToRender + currentState.blocsIncrement;
                            return {
                                blocsToRender: newBlocsToRender,
                            };
                        }, () => {
                            if (this.state.blocsToRender >= totalBlocs) {
                                this.checkScrollReset();
                            }
                        });
                    }
                }, this.state.blocsDelay);
            }
        }
    }

    checkScrollReset() {
        if (this.scrollToRestore) {
            if (this.scrollContainer.current) {
                const element = this.scrollContainer.current;
                element.scrollTop = this.scrollToRestore;
                if (element.scrollHeight >= this.scrollToRestore + element.clientHeight) {
                    this.scrollToRestore = null;
                }
            }
        }
    }

    layoutInit = () => {
        this.checkScrollReset();
    };

    componentDidMount() {
        const page = this.props.pages[this.props.template] as States.ICurrentPageState;
        if (page && this.scrollContainer.current) {
            const element = this.scrollContainer.current;
            let debounce;
            element.onscroll = () => {
                if (debounce) {
                    cancelAnimationFrame(debounce);
                }

                if (window && (element.scrollTop > window.innerHeight * 0.8)) {
                    this.setState({ displayBackToTop: true });
                } else {
                    this.setState({ displayBackToTop: false });
                }

                debounce = requestAnimationFrame(() => {
                    debounce = null;
                    const currentPage = this.props.pages[this.props.template] as States.ICurrentPageState;
                    currentPage.restoreScroll = element.scrollTop;
                });
            };
        }
        this.checkScrollReset();
        this.checkRemainingBlocs();
    }

    componentDidUpdate(prevprops: IDynamicPageProps) {
        this.checkRemainingBlocs();
        const currentUser = this.props?.user?.currentUser?.detail;
        const previousUser = prevprops.user?.currentUser?.detail;
        const userHasChanged = (currentUser && !previousUser);
        const userDataHasChanged = (currentUser && currentUser !== previousUser);

        if (userHasChanged || userDataHasChanged) {
            this.setState({
                pageLoading: true
            });
            const act: any = this.props.pageActions.refreshCurrentPageBlocs();
            act.then(() => {
                if (!this.unmounted) {
                    this.setState({ pageLoading: false });
                }
            });
        }
    }

    // static getDerivedStateFromProps(props: IDynamicPageProps) {
    //     return {
    //         datacontext: pageDataContext(props)
    //     };
    // }

    // componentWillReceiveProps(props: IDynamicPageProps) {
    //     this.setState({ datacontext: pageDataContext(props) });
    // }

    onPageLoading = (loading: boolean) => {
        this.setState({ pageLoading: loading });
    };

    render() {
        const { event, community } = this.props;
        const datacontext = pageDataContext(this.props);
        // logger.verbose("render dynamic page " + this.props.template);
        const page = this.props.currentpage || this.props.pages[this.props.template] as States.ICurrentPageState;
        if (!page) {
            return <div className="no-page-yet" />;
        }

        if (page.notFound === true) {
            return (
                <div className={'dynamicpage pagenotfound ' + (this.props.className || '')}>
                    <div className="update-container">
                        <AppLabel component="div" className="message" i18n="page.notfound" />
                    </div>
                </div>
            );
        }

        if (!page.template || !page.template.config || !page.template.config.blocs || page.template.disabled) {
            return (
                <div className={'dynamicpage ' + (this.props.className || '')}>
                    <DynamicPageMetaData
                        page={page}
                        datacontext={datacontext}
                        event={event}
                        community={community}
                        rootwebsite={this.props.rootwebsite}
                        currentLanguageCode={this.props.i18n.currentLanguageCode}
                        webmasterConfiguration={this.props.webmasterConfiguration}
                        pageActions={this.props.pageActions}
                        pages={this.props.pages}
                        customcontext={this.props.customcontext}
                        match={this.props.match}
                        ssr={this.props.ssr}
                    />

                    <DynamicPageMicrodata
                        page={page}
                        datacontext={datacontext}
                        event={event}
                        community={community}
                        rootwebsite={this.props.rootwebsite}
                        currentLanguageCode={this.props.i18n.currentLanguageCode}
                        webmasterConfiguration={this.props.webmasterConfiguration}
                        pageActions={this.props.pageActions}
                        pages={this.props.pages}
                        customcontext={this.props.customcontext}
                        match={this.props.match}
                        ssr={this.props.ssr}
                    />
                    <div className="update-container">
                        <AppTextLabel component="div" className="message" i18n="page.location.error" />
                    </div>
                </div>
            );
        }

        if (this.props.requireEntity && !page.context.entity) {
            return (
                <div className={'dynamicpage ' + (this.props.className || '')}>
                    Error, data not found...
                </div>
            );
        }

        const dynamicPageClasses = ['dynamicpage dynamicpage-' + page?.tinyurl];
        if (this.props.className) {
            dynamicPageClasses.push(this.props.className);
        }
        if (page.template.config.narrowcontent) {
            dynamicPageClasses.push('narrowcontent');
        }
        if (page.template.config.fullsize) {
            dynamicPageClasses.push('fullsize');
        }

        const blocs = []; const headers = []; const
            footers = [];

        let sidebarVisible = false;
        if (this.props.visualstate && this.props.visualstate.indexOf('L') >= 0) {
            sidebarVisible = true;
        }
        let blocItems;
        const pageProperties = page?.template?.config?.properties;
        const showPage = pageProperties
            ? filterBlocs(event, community, datacontext, page.template?.config?.properties, null) : true;
        if (!showPage) {
            blocItems = [];
        } else {
            blocItems = page.template.config.blocs.filter((template: VisualTheme.IBlocTemplateBase, templateIdx) => {
                // eslint-disable-next-line no-param-reassign
                template._index = templateIdx;
                return filterBlocs(event, community, datacontext, template, templateIdx);
            });
        }

        const sideBlocItems = page.template?.config?.sidebar?.blocs?.filter(
            (template: VisualTheme.IBlocTemplateBase, templateIdx) => {
                // eslint-disable-next-line no-param-reassign
                template._index = templateIdx;
                return filterBlocs(event, community, datacontext, template, templateIdx);
            }
        );

        if (sideBlocItems && sideBlocItems.length) {
            if (sidebarVisible) {
                dynamicPageClasses.push('withsidebar');
            }
        }

        if (this.props.pagefooter?.config?.blocs?.length && showPage) {
            const footerBlocs = this.props.pagefooter.config.blocs.filter(
                (template: VisualTheme.IBlocTemplateBase, templateIdx) => {
                    // eslint-disable-next-line no-param-reassign
                    (template as any).__pagefooter = true;
                    return filterBlocs(event, community, datacontext, template, templateIdx);
                }
            );
            // .map((b) => {
            //     (b as any).__pagefooter = true;
            //     return b;
            // });
            blocItems.push(...footerBlocs);
        }

        blocItems.forEach((template: VisualTheme.IBlocTemplateBase, templateIdx) => {
            let templateToRender = template;
            if (template && template.import) {
                const alltemplates = event.data.templates.data;
                const importedtemplate = alltemplates.filter((t) => t.contentType === template.import.contentType
                    && t.tinyUrl === template.import.tinyUrl)[0];

                if (importedtemplate) {
                    templateToRender = importedtemplate.config.blocs.filter((b) => b.id === template.import.blocId)[0];
                }
            }

            if (templateToRender?.content && (!this.state.allowProgressiveRendering || templateIdx <= this.state.blocsToRender)) {
                templateToRender.content.filter(
                    (content, contentIdx) => filterBlocContent(this.props.user, datacontext, content, contentIdx)
                ).forEach((content, contentIdx) => {
                    const contentDef = blocsCatalog[content.type];
                    if (contentDef) {
                        if (contentDef.customPageCSSClass) {
                            dynamicPageClasses.push(contentDef.customPageCSSClass);
                        }
                        let blocState = null;
                        if (page.data && page.data[content.id]) {
                            blocState = page.data[content.id];
                        }
                        if (contentDef.headerComponents) {
                            contentDef.headerComponents.forEach((component, idx) => {
                                headers.push(<DynamicPageBlocContent
                                    {...this.props}
                                    key={'bloc' + template.id + '#' + templateIdx
                                        + '#' + contentIdx + '#' + content.id + '#header#' + idx}
                                    page={page}
                                    bloctemplate={templateToRender}
                                    datacontext={datacontext}
                                    kind="headerComponent"
                                    customComponent={component}
                                    theme={null}
                                    contenttemplate={content}
                                    blocState={blocState}
                                    onLayoutInit={this.layoutInit}
                                    onPageLoading={this.onPageLoading}
                                />);
                            });
                        }
                        if (contentDef.footerComponent && this.props.customcontext && this.props.customcontext.fromModal) {
                            footers.push(
                                <DynamicPageBlocContent
                                    {...this.props}
                                    key={'bloc' + template.id + '#' + templateIdx + '#' + contentIdx + '#' + content.id}
                                    page={page}
                                    bloctemplate={templateToRender}
                                    datacontext={datacontext}
                                    kind="footerComponent"
                                    customComponent={contentDef.footerComponent}
                                    theme={null}
                                    contenttemplate={content}
                                    blocState={blocState}
                                    onLayoutInit={this.layoutInit}
                                    onPageLoading={this.onPageLoading}
                                />
                            );
                        }
                    } else {
                        logger.verbose('missing bloc ' + content.type);
                    }
                });

                blocs.push(
                    // <SimpleFadeTransition key={'bloc' + template.id + '#' + templateIdx} exit={false}>
                    <DynamicPageBloc
                        {...this.props}
                        key={'bloc' + template.id + '#' + templateIdx}
                        page={page}
                        bloctemplate={templateToRender}
                        datacontext={datacontext}
                        onLayoutInit={this.layoutInit}
                        onPageLoading={this.onPageLoading}
                    />
                    // </SimpleFadeTransition>
                );
            }
        });

        let backToTop;
        const eventTopEnabled = event?.detail?.configuration?.companion?.enabledBackToTop;
        const communityTopEnabled = community?.detail?.configuration?.companion?.enabledBackToTop;
        const topEnabled = eventTopEnabled || communityTopEnabled;

        if (this.state.displayBackToTop && topEnabled) {
            backToTop = <div id="back-to-top" className="bloctheme">
                <button
                    type="button"
                    onClick={() => {
                        // eslint-disable-next-line react/no-find-dom-node
                        const scroll = this.scrollContainer?.current;
                        if (scroll && scroll.scrollTop) {
                            scroll.scrollTop = 0;
                        }
                    }}
                ><i className="inwink-chevron-up" /></button>
            </div>;
        }

        if (headers.length) {
            dynamicPageClasses.push('withpageheader');
        }
        if (footers.length) {
            dynamicPageClasses.push('withpagefooter');
        }

        let backgroundImage;
        //fallback for old site using background
        if (page.template?.config?.pagestyle?.background && !page.template?.config?.pagestyle?.backgroundImage) {
            backgroundImage = page.template?.config?.pagestyle?.background;
        } else {
            backgroundImage = page.template?.config?.pagestyle?.backgroundImage;
        }


        if (page.template?.config?.pagestyle?.backgroundImageMobile) {
            if (this.props.visualstate && this.props.visualstate.indexOf('S') < 0) {
                backgroundImage = page.template?.config?.pagestyle?.backgroundImageMobile;
            }
        }

        const pagestyle = {
            backgroundImage: backgroundImage,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center"
        };
        const containerstyle = page.template.config.bloccontainerstyle;

        return (
            <div className={dynamicPageClasses.join(' ')} style={pagestyle}>
                <DynamicPageMetaData
                    page={page}
                    datacontext={datacontext}
                    event={event}
                    community={community}
                    rootwebsite={this.props.rootwebsite}
                    currentLanguageCode={this.props.i18n.currentLanguageCode}
                    webmasterConfiguration={this.props.webmasterConfiguration}
                    pageActions={this.props.pageActions}
                    pages={this.props.pages}
                    customcontext={this.props.customcontext}
                    match={this.props.match}
                    ssr={this.props.ssr}
                />

                <DynamicPageMicrodata
                    page={page}
                    datacontext={datacontext}
                    event={event}
                    community={community}
                    rootwebsite={this.props.rootwebsite}
                    currentLanguageCode={this.props.i18n.currentLanguageCode}
                    webmasterConfiguration={this.props.webmasterConfiguration}
                    pageActions={this.props.pageActions}
                    pages={this.props.pages}
                    customcontext={this.props.customcontext}
                    match={this.props.match}
                    ssr={this.props.ssr}
                />
                {headers.length ? <div className="dynamicpage-header">{headers}</div> : null}
                <div
                    style={containerstyle}
                    className="dynamicpage-blocscontainer dynamicpage-maincontent"
                >
                    {!showPage ? <div className="no-show-page-message-container">
                        <AppLabel component="h3" i18n="page.noshow.message" />
                    </div> : ""}
                    <div className="dynamicpage-scrollcontent" ref={this.scrollContainer}>
                        {page?.wrapWithUserRestricted ?
                            <AppUserRestrictedComponent className='bloctheme dynamicpage-user-restricted'>
                                {blocs}
                            </AppUserRestrictedComponent>
                            : blocs}
                        {backToTop}
                    </div>
                </div>
                <DynamicPageSidebar
                    {...this.props}
                    page={page}
                    blocs={sideBlocItems}
                    datacontext={datacontext}
                    sidebarVisible={sidebarVisible}
                    onLayoutInit={this.layoutInit}
                    onPageLoading={this.onPageLoading}
                />
                {footers.length ? <div className="dynamicpage-footer">{footers}</div> : null}
                {this.pageInjection(page)}
                <div className={'dynamicpage-loader bloctheme ' + (this.state.pageLoading ? 'visible' : 'hidden')}>
                    <div className="loader-wrapper bloc-defaultbg"><Loader className="big" /></div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        event: state.event,
        community: state.community,
        rootwebsite: state.rootwebsite,
        user: state.user,
        i18n: state.i18n,
        pages: state.pages,
        ssr: state.ssr
    };
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        loadBlocsActions: bindActionCreators(pageActions, dispatch)
    };
}

const DynamicPageControl: new (DynamicPageProps) => React.Component<IDynamicPageProps, any> = withUrlService()(withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DynamicPageComponent as any) as any
) as any);

// eslint-disable-next-line import/no-mutable-exports
export let DynamicPage = DynamicPageControl;

export function hookDynamicPage(callback) {
    DynamicPage = callback(DynamicPageControl);
}
