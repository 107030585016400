import { Loader } from '@inwink/loader';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { Entities } from '@inwink/entities/entities';
import * as React from 'react';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import './pagedList.less';
import { AppTextLabel } from '../services/i18nservice';
import type { IInwinkEntityQueryOptions } from '@@api/front/datasource';
import type { IInwinkEntityV3QueryOptions } from '@@api/front';

interface IPagedListProps {
    isLoading?: boolean;
    onCompleted?: (res?) => void;
    onRowCountChanged?: (count: any) => void;
    selectedItems?: any[];
    searchterm?: string;
    filters?: any;
    loadItems: (options: IInwinkEntityQueryOptions | IInwinkEntityV3QueryOptions) => Promise<any>;
    onItemClicked: (item: any) => void;
    pageSize: number;
    rowHeight: number;
    noItems: any;
    searchField?: boolean;
    onSearchTermChange?: (searchterm: string) => void;
    renderItem: (entity: any, item: any, onItemClicked: (item) => void) => void;
    renderSelectedItem?: (item: any) => void;
    i18nHelper?: Entities.i18nHelper;
    rerenderOnClick?: boolean;
}

interface IPagedListState {
    items: any[];
    page: number;
    pageSize: number;
    isLoading: boolean;
    hasMoreItemsToFetch: boolean;
}

const listPageSize = 20;

@withI18nHelper()
export class PagedList extends React.Component<IPagedListProps, IPagedListState> {
    fetching: boolean;

    virtualList: any;

    constructor(props: IPagedListProps) {
        super(props);

        this.state = {
            items: [],
            page: 0,
            isLoading: true,
            pageSize: props.pageSize || listPageSize,
            hasMoreItemsToFetch: false
        };
    }

    componentDidMount() {
        this.props.loadItems({ page: { index: this.state.page,
            size: this.state.pageSize,
            returnTotalCount: true } }).then((res) => {
            if (res) {
                this.setState({ 
                    items: res[0].data,
                    isLoading: false,
                    hasMoreItemsToFetch: this.getHasMoreItemsToFetch(res)
                });
            }
        });
    }

    getSnapshotBeforeUpdate(prevProps: IPagedListProps) {
        if (JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)) {
            this.search(this.props.filters);
        }
        if (prevProps.selectedItems !== this.props.selectedItems) {
            if (this.state.items && this.state.items.length) {
                const oldItems = Object.assign([], this.state.items);
                this.setState({ items: this.sort(oldItems) });
            }
        }
    }

    search(filters) {
        this.setState({ isLoading: true });
        const options: IInwinkEntityV3QueryOptions = {
            page: { 
                index: 0,
                size: this.state.pageSize
            },
            filters
        };
        this.props.loadItems(options).then((res) => {
            this.setState({ items: res[0].data, isLoading: false, page: 0 }, () => {
                this.virtualList.forceUpdate();
            });
        }, (err) => {
            console.error(err);
        });
    }

    sort(its) {
        let items = its;
        if (this.props.selectedItems && this.props.selectedItems.length > 0) {
            items = items.filter((i) => {
                if (this.props.selectedItems.filter((s) => s === i.id)[0]) {
                    return false;
                }
                return true;
            });
        }

        return items;
    }

    _isRowLoaded = (index) => {
        const isLoaded = this.state.items[index];
        return !!isLoaded;
    };

    getHasMoreItemsToFetch = (result: any[]): boolean => {
        const items = Array.isArray(result) && result.length > 0 ? result[0].data : [];
        const hasMoreItems = !(items.length < this.state.pageSize);
        return hasMoreItems;
    };

    getExistingItemIds = (): Set<string> => {
        return new Set(this.state.items.map(item => item.id));
    };

    getNewItemsFromResult = (result: any[]): any[] => {
        const resItems = Array.isArray(result) && result.length > 0 ? result[0].data : [];

        const newitems = [...this.state.items];
        const existingIds = this.getExistingItemIds();

        resItems.filter(i => !existingIds.has(i.id)).forEach((i) => newitems.push(i));

        return newitems;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _loadMoreRows = ({ startIndex, stopIndex }) => {
        if (!this.fetching && this.state.hasMoreItemsToFetch 
            && stopIndex >= this.state.items.length) {
            this.fetching = true;
            const page = this.state.page + 1;
            const options: any = { 
                page: { 
                    index: this.state.page + 1,
                    size: this.state.pageSize,
                    returnTotalCount: true 
                },
                search: this.props.searchterm,
                filters: this.props.filters 
            };
            
            return this.props.loadItems(options).then((result) => {
                this.fetching = false;

                const newItems = this.getNewItemsFromResult(result);

                this.setState(() => ({ 
                    page: page,
                    items: this.sort(newItems),
                    hasMoreItemsToFetch: this.getHasMoreItemsToFetch(result)
                }));

                if (this.props.onRowCountChanged) this.props.onRowCountChanged(result.totalCount);
            },
            (err) => {
                this.fetching = false;
                console.error(err);
            });
        }

        return Promise.resolve();
    };

    onItemClicked = (item) => {
        this.props.onItemClicked(item);

        if (this.props.rerenderOnClick)
            this.setState((prevState) => ({items: [...prevState.items]}));
    };

    renderItem = (rowItem) => {
        const item = this.state.items && this.state.items[rowItem.index];
        return this.props.renderItem(item, rowItem, () => this.onItemClicked(item)) as any;
    };

    render() {
        let searchField;
        let selectedItems;
        let list;
        const i18n = this.props.i18nHelper;
        selectedItems = <AppTextLabel component="div" className="no-filter" i18n="pagedlist.list.nofilter" />;
        if (this.props.searchField) {
            searchField = <div className="searchbox">
                <input
                    placeholder={i18n.translate("pagedlist.search.text")}
                    type="search"
                    onChange={(arg) => this.props.onSearchTermChange(arg.currentTarget.value)}
                />
            </div>;
        }

        if (this.props.selectedItems) {
            if (this.props.selectedItems.length) {
                selectedItems = this.props.selectedItems.map((item) => {
                    return this.props.renderSelectedItem(item);
                });
            }
        }

        if (this.state.isLoading) {
            list = <Loader />;
        } else {
            list = <InfiniteLoader
                isRowLoaded={this._isRowLoaded}
                loadMoreRows={this._loadMoreRows}
                rowCount={1000}
            >
                {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    ({ onRowsRendered, registerChild }) => (
                        <AutoSizer>
                            {({ height, width }) => (<List
                                ref={(ref) => { this.virtualList = ref; }}
                                height={height}
                                rowCount={this.state.items && this.state.items.length}
                                rowHeight={this.props.rowHeight}
                                onRowsRendered={onRowsRendered}
                                noRowsRenderer={this.props.noItems}
                                rowRenderer={this.renderItem}
                                width={width}
                                data={this.state.items}
                            />)}
                        </AutoSizer>
                    )
                }
            </InfiniteLoader>;
        }

        return <div className="paged-list">
            {searchField}
            {this.props.renderSelectedItem ? <div className="selected-container">{selectedItems}</div> : null}
            <div className="paged-list-items">{list}</div>
        </div>;
    }
}
