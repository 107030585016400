import * as assign from 'lodash/assign';
import * as React from 'react';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { translateBag } from '@inwink/i18n/utils/translate';
import { DynLabel } from '../../services/i18nservice';
import type { IFilterProps } from "./definitions";
import { getExpectedPickerTypeFromItemsCount, ItemsPicker } from './itemspicker';
import type { IItemsPickerItem } from "./itemspicker.props";
import type { IInwinkEntityV3QueryOptions } from '@@api/front';

interface IEntityFieldMultiSelectFilterState {
    expanded: boolean;
    items: IItemsPickerItem[];
}

@withI18nHelper()
export class EntityFieldMultiSelectFilter extends React.Component<IFilterProps, IEntityFieldMultiSelectFilterState> {
    constructor(props: IFilterProps) {
        super(props);
        this.state = {
            expanded: this.props.filterField.isExpanded ? this.props.filterField.isExpanded : false,
            items: this.getItems(props)
        };
    }

    componentDidUpdate(prevprops: IFilterProps) {
        if (prevprops.i18n !== this.props.i18n) {
            this.setState({ items: this.getItems(this.props) });
        }
    }

    getItems(props: IFilterProps) {
        let items = props.entityField.valuesList && props.entityField.valuesList.map((v) => {
            let label;
            if (v && v.labels) {
                label = translateBag(this.props.i18n, v.labels);
            } else if (props.filterField.itemLabel) {
                label = props.filterField.itemLabel(v.key);
            } else if (props.entitySchema?.entityName) {
                const key = (props.entitySchema?.entityName + "." + props.entityField.key + "." + v.key).toLowerCase();
                label = (this.props as any).i18nHelper.translate(key) || key;
            }
            const item: IItemsPickerItem = {
                id: v && v.key,
                label: label,
                lowerCaseLabel: label && label.toLowerCase(),
                data: v
            };

            return item;
        });

        items = items.sort((a, b) => {
            if (props.entityField.valuesListSort) {
                if (props.entityField.valuesListSort === 'az') {
                    if (a.lowerCaseLabel > b.lowerCaseLabel) {
                        return 1;
                    }
                    if (a.lowerCaseLabel < b.lowerCaseLabel) {
                        return -1;
                    }
                    return 0;
                } if (props.entityField.valuesListSort === 'za') {
                    if (a.lowerCaseLabel < b.lowerCaseLabel) {
                        return 1;
                    }
                    if (a.lowerCaseLabel > b.lowerCaseLabel) {
                        return -1;
                    }
                    return 0;
                }
            }

            const aIx = props.entityField.valuesList.findIndex((v) => v && v.key === a.id);
            const bIx = props.entityField.valuesList.findIndex((v) => v && v.key === b.id);
            if (aIx < bIx) {
                return -1;
            } if (aIx > bIx) {
                return 1;
            }
            return 0;
        });

        return items;
    }

    getPagedItems = (options: IInwinkEntityV3QueryOptions): Promise<IItemsPickerItem[]> => {
        if (!this.state.items?.length)
            return Promise.resolve([]);

        const page = options?.page;
        let items = this.state.items;
        if (page?.index !== undefined && page?.size !== undefined) {
            const startIndex = page.index * page.size;

            if (startIndex < items.length) {
                const endIndex = (page.index + 1) * page.size;
                items = options.search ? items.filter((i) => i.lowerCaseLabel?.includes(options.search?.toLowerCase())) : items;
                items = items.slice(startIndex, endIndex < items.length ? endIndex : undefined);
            } else
                items = [];
        }

        return Promise.resolve(items);
    };

    onItemsChanged = (selection: string[]) => {
        const newfilter = assign({}, this.props.filters, {
            [this.props.entityField.key]: selection
        });

        if (selection.length === 0) {
            delete newfilter[this.props.entityField.key];
        }

        if (this.props.filterChanged) this.props.filterChanged({ field: this.props.entityField, val: newfilter });
        this.setState({});
    };

    filterChange(arg, name: string, label: string) {
        const existing = this.props.getActiveFilters(this.props.entityField.key);
        const selecteditems = existing.map((s) => s);

        const selected = (arg.target as HTMLInputElement).checked;
        if (selected) {
            if (!(selecteditems.indexOf(label) >= 0)) selecteditems.push(label);
        } else {
            const idx = selecteditems.indexOf(label);
            if (idx >= 0) {
                selecteditems.splice(idx, 1);
            }
        }

        const res = {};
        let newfilter = {};
        if (selecteditems.length > 0) {
            res[this.props.entityField.key] = selecteditems;
            newfilter = assign({}, this.props.filters, res);
        } else {
            newfilter = assign({}, this.props.filters);
            delete newfilter[this.props.entityField.key];
        }

        if (this.props.filterChanged) {
            this.props.filterChanged({ field: this.props.entityField, val: newfilter });
        }
    }

    toggleExpanded = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        this.setState((state) => {
            return {
                expanded: !state.expanded
            };
        });
    };

    getLabel = (value: IItemsPickerItem) =>{
        return value?.label;
    };

    render() {
        let itemsbloc;
        const classNames = [
            "filter", 
            "expandable",
            "filter-multiselect",
            (this.state.expanded ? "expanded" : "collapsed")
        ];
        const existing = this.props.getActiveFilters(this.props.entityField.key);
        const key = this.props.entityField?.key || this.props.filterField.name;
        
        if (key) {
            classNames.push("field-" + key);
        }

        if (this.state.expanded) {
            itemsbloc = <ItemsPicker
                {...this.props}
                i18n={this.props.i18n}
                getItems={this.getPagedItems}
                pickername={"entityfilter" + this.props.entityField.key}
                pickerType={getExpectedPickerTypeFromItemsCount(this.state.items?.length)}
                selection={existing}
                selectionChanged={this.onItemsChanged}
                getItemLabel={this.getLabel}
                selectedItems={this.state.items?.filter((i) => i?.id && existing?.includes(i.id))}
            />;
        }

        let stateMark;
        if (existing.length) {
            stateMark = <span className="statemark bloc-accentbg">
                {existing.length}
            </span>;
        }

        return <div className={classNames.join(" ")}>
            <div className="fieldtoggle clickable" onClick={this.toggleExpanded}>
                <DynLabel
                    component="label"
                    i18n={this.props.filterField.label ? this.props.filterField.label : this.props.entityField.labels}
                    className="fieldlabel clickable"
                />
                <div className="fieldtogglestate clickable">
                    {stateMark}
                    <span className="status bloc-lighttext inwink-chevron-right" />
                </div>
            </div>
            <div className="multiselect-items">
                {itemsbloc}
            </div>
        </div>;
    }
}
