import * as React from 'react';
import { TransitionGroup } from 'react-transition-group';
import { Loader } from '@inwink/loader';
import { FadeTransition } from './transitions';
import "./loadable.less";
import { LoadableProps } from '@inwink/loadable';

export interface ILoadableManager {
    progress(data);
    ready();
}

export const LoadableContext = React.createContext<ILoadableManager>(null);

export interface ILoadableProps {
    transitionName?: string;
    className?: string;
    loaderClassName?: string;
    component: () => Promise<any>;
    componentProps?: any;
    ssr?: (props) => any;
    loader?: (props) => any;
    minDelay?: number;
    waitForReady?: boolean;
    forward?: boolean;
    parent?: ILoadableManager;
}

class LoadableComponent extends React.Component<ILoadableProps, any> {
    readyPromise;

    readyComplete;

    readyError;

    manager: ILoadableManager;

    constructor(props) {
        super(props);

        this.readyPromise = (props.waitForReady ? new Promise((complete, error) => {
            this.readyComplete = complete;
            this.readyError = error;
        }) : null);

        this.manager = {
            progress: this.trackProgress,
            ready: this.componentReady
        };

        this.state = {
            loading: (!props.ssr),
            component: (props.ssr || null),
            loaderProps: null
        };
    }

    trackProgress = (data) => {
        this.setState({ loaderProps: data });
    };

    componentReady = () => {
        if (this.readyComplete) this.readyComplete();
    };

    componentDidMount() {
        if (this.props.forward) {
            const parent = this.props.parent;
            if (parent) {
                this.manager.progress = parent.progress;
                this.manager.ready = parent.ready;
            }
        }

        if (!this.state.component) {
            const promises = [this.props.component().then((component) => {
                if (component.waitForReady && !this.readyPromise) {
                    this.readyPromise = new Promise((complete, error) => {
                        this.readyComplete = complete;
                        this.readyError = error;
                    });
                }
                return component;
            })];
            if (this.props.minDelay) {
                promises.push(new Promise((resolve) => setTimeout(resolve, this.props.minDelay)));
            }
            Promise.all(promises).then((results) => {
                this.setState({ component: results[0] });
                if (this.readyPromise) {
                    this.readyPromise.then(() => {
                        this.setState({ loading: false });
                    });
                } else {
                    this.setState({ loading: false });
                }
            });
        }
    }

    render() {
        const contents = [];
        if (this.state.component) {
            contents.push(<FadeTransition key="content">
                <div className="loadablecontent">{React.createElement(this.state.component, this.props.componentProps)}</div>
            </FadeTransition>);
        }

        if (!this.props.forward && this.state.loading) {
            if (this.props.loader) {
                contents.push(<FadeTransition key="loader">
                    <div className={"loadablecomponentloader"
                        + (this.props.loaderClassName ? " " + this.props.loaderClassName : "")}>
                        {React.createElement(this.props.loader, this.state.loaderProps)}
                    </div>
                </FadeTransition>);
            } else {
                contents.push(<FadeTransition key="loader">
                    <div className={"loadablecomponentloader"
                        + (this.props.loaderClassName ? " " + this.props.loaderClassName : "")}>
                        <Loader />
                    </div>
                </FadeTransition>);
            }
        }

        return <LoadableContext.Provider value={this.manager}><TransitionGroup
            component="div"
            className={"loadablecomponent slowtransition" + (this.props.className ? " " + this.props.className : "")}
        >
            {contents}
        </TransitionGroup>
        </LoadableContext.Provider>;
    }
}

export function Loadable(props: LoadableProps) {
    return <LoadableContext.Consumer>
        {(loadable) => <LoadableComponent {...props} parent={loadable} />}
    </LoadableContext.Consumer>;
}