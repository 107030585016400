import * as React from 'react';
import * as assign from 'lodash/assign';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { translateBag } from '@inwink/i18n/utils/translate';
import { States } from '@@services/services';
import type { IEntityFilterProps } from "./definitions";
import type { IItemsPickerItem, IItemsPickerType } from "./itemspicker.props";
import { getExpectedPickerTypeFromItemsCount, ItemsPicker } from './itemspicker';
import type { IInwinkEntityV3QueryOptions } from '@@api/front';
import { IRequestManager } from '@inwink/apibase/requestmanager';
import type { VisualTheme } from '@inwink/entities/visualtheme';

interface IEntityFieldEntityFilterState {
    expanded: boolean;
    items: IItemsPickerItem[];
    entityName: string;
    loading: boolean;
    pickerType?: IItemsPickerType;
    selectedItems?: IItemsPickerItem[];
    filters?: any;
}

export interface IEntityFieldEntityFilterProps extends IEntityFilterProps {
    i18n?: States.i18nState;
    event?: States.IEventState;
    community?: States.ICommunityState;
    pickerType?: IItemsPickerType;
    selection?: string[];
    requestManager: IRequestManager;
    templatesData: States.IDataStoreWithTemplates;
}

@connectwith((state: States.IAppState) => {
    return {
        i18n: state.i18n,
        event: state.event,
        community: state.community
    };
})
export class EntityFieldEntityFilter extends React.Component<IEntityFieldEntityFilterProps, IEntityFieldEntityFilterState> {
    constructor(props: IEntityFieldEntityFilterProps) {
        super(props);
        this.state = {
            expanded: this.props.filterField.isExpanded ? this.props.filterField.isExpanded : false,
            items: null,
            pickerType: this.props.pickerType,
            entityName: this.props.filterField.fromEntities,
            loading: true
        };
    }

    componentDidMount() {
        const selection = this.props.getActiveFilters(this.props.filterField.name);
        const filters = selection?.length && {
            filters: {
                id: {
                    $in: selection
                }
            }
        };

        return Promise.all([
            filters && this.getItems(filters, selection),
            this.props.entityFilterHandler?.countItems(this.props, null, this.props.requestManager)
        ]).then((res) => {
            this.setState({ 
                selectedItems: res?.[0],
                pickerType: getExpectedPickerTypeFromItemsCount(res?.[1] ?? 0),
                loading: false
            });
        });
    }

    getItems = (options: IInwinkEntityV3QueryOptions, selection?: string[]): Promise<IItemsPickerItem[]> => {
        if (!this.state.entityName)
            return Promise.resolve([]);

        if (this.props.entityFilterHandler.queryItems) {
            return this.props.entityFilterHandler?.queryItems(
                this.state.entityName, options, this.props.requestManager).then((res) => {
                const itemData = res?.data?.map((r) => { 
                    const label = this.getLabel({[this.state.entityName]: r});
                    return {
                        id: r.id,
                        label,
                        lowerCaseLabel: label?.toLowerCase(),
                        [this.state.entityName]: r
                    };
                });
    
                return itemData;
            });
        } else if (this.props.entityFilterHandler.getItems) {
            const filterItemsOptions = {
                ...options,
                itemIds: selection
            };

            return this.props.entityFilterHandler?.getItems(this.props, filterItemsOptions).then((items) => {
                return items;
            });
        }

        return Promise.resolve([]);
    };

    onSelectionChanged = (selection: string[]) => {
        const newfilter = assign({}, this.props.filters, {
            [this.props.filterField.name]: selection
        });

        if (selection.length === 0) {
            delete newfilter[this.props.filterField.name];
        }

        if (this.props.filterChanged) this.props.filterChanged({ field: this.props.filterField, val: newfilter });
    };

    onEntitySelectionChanged = (items: IItemsPickerItem[]) => {
        const selection = items.map((i) => i?.[this.props.filterField?.fromEntities]?.[this.props.filterField?.fromEntityName]?.id
                || i?.[this.props.filterField?.fromEntities]?.id)
            .filter(x => !!x);

        this.onSelectionChanged(selection);
        this.setState({selectedItems: items});
    };

    toggleExpanded = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        this.setState((state) => {
            return {
                expanded: !state.expanded
            };
        });
    };

    getLabel = (value: {[entityName: string]: any}) => {
        if (!value)
            return "";

        const convertLabel = (val: string | VisualTheme.IAppLabel) => {
            if (typeof val === "object") {
                return translateBag(this.props.i18n, val);
            }

            return val;
        };

        const { filterField } = this.props;

        const entities = value?.[filterField?.fromEntities] ?? {};
        
        const entity = filterField?.fromEntityName 
            ? entities[filterField.fromEntityName] 
            : entities;

        const mappedLabels = this.props.filterField?.mapping?.label;
        if (mappedLabels?.length) {
            return mappedLabels.map((l) => {
                return convertLabel(entity?.[l]);
            }).join(" ");
        }
        
        return convertLabel(entity?.name || entity?.title);
    };

    render() {
        const classNames = [
            "filter",
            "expandable",
            "filter-entityselect",
            this.state.expanded ? "expanded" : "collapsed"
        ];

        if (this.props.filterField.name) {
            classNames.push("field-" + this.props.filterField.name);
        }
        const field = this.props.filterField;
        const label = translateBag(this.props.i18n, field.label);
        let itemsbloc;
        const existing = this.props.getActiveFilters(this.props.filterField.name);
        const isReadonly = (field as any).isReadOnly;


        if (this.state.expanded && !this.state.loading) {
            itemsbloc = <ItemsPicker
                {...this.props}
                i18n={this.props.i18n}
                selectedItems={this.state.selectedItems}
                pickername={"entityfilter" + field.name}
                selection={existing}
                selectionChanged={this.onSelectionChanged}
                disableAutoClose={this.props.disableAutoClose}
                pickerType={this.state.pickerType}
                isReadonly={isReadonly}
                entityName={this.props.filterField?.fromEntities}
                entitySelectionChanged={this.onEntitySelectionChanged}
                getItems={this.getItems}
                getItemLabel={this.getLabel}
            />;
        }

        let stateMark;
        if (existing.length) {
            stateMark = <span className="statemark bloc-accentbg">
                {existing.length}
            </span>;
        }

        return <div className={classNames.join(" ")}>
            <div className="fieldtoggle clickable" onClick={this.toggleExpanded}>
                <label className="fieldlabel clickable">
                    <span>{label}</span>
                    {(field as any).isMandatory ? <span className="requiredmark">*</span> : null}
                </label>
                <div className="fieldtogglestate clickable">
                    {stateMark}
                    <span className="status bloc-lighttext inwink-chevron-right" />
                </div>
            </div>
            <div className="multiselect-items">
                {itemsbloc}
            </div>
        </div>;
    }
}
